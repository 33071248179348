import React, { useState } from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import Banner from './Banner';
import ContentFilters from './data_hub/ContentFilters';
import DataGrid from './data_grid/DataGrid';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { TCurrency } from '../../src/types/BaseTypes';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import {
  DataHubGraphQLParam,
  DataHubNumberGraphQLParamToURLParam,
  DataHubStringGraphQLParamToURLParam,
  DataHubURLParam,
  DataHubURLParamToGraphQLParam,
  DateFilter,
  Sort,
  SortDirections,
  StringToStringMap,
  SupportedSortKeys,
  getDataHubTableColumnNamesForDisplay,
} from '../../src/types/DataHubTypes';
import { DataHubTable, TableHighlightFragmentFragment, UserModel } from '../generated/graphql';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from './common/MenuDropdown';
import { TableHighlightFragment, TransactionCellRowFragment } from '../apollo/GraphQLFragments';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';

const useStyles = createUseStyles({
  dataHubTable: {
    '&.tabulator': {
      fontSize: '13px',
    },
  },
  horizontallyScrollableContent: {
    minHeight: '480px',
    overflowX: 'scroll',
    paddingBottom: '20px', // to show the scroll bar
  },
});

const QUERY = gql`
  ${TransactionCellRowFragment}
  ${TableHighlightFragment}
  query GetDataHubTable(
    $entityId: String
    $${DataHubGraphQLParam.ID}: String
    $${DataHubGraphQLParam.IMPORT_START_TIME_MS}: Float
    $${DataHubGraphQLParam.IMPORT_END_TIME_MS}: Float
    $${DataHubGraphQLParam.INVOICE_ISSUED_START_TIME_MS}: Float
    $${DataHubGraphQLParam.INVOICE_ISSUED_END_TIME_MS}: Float
    $${DataHubGraphQLParam.INVOICE_DUE_START_TIME_MS}: Float
    $${DataHubGraphQLParam.INVOICE_DUE_END_TIME_MS}: Float
    $${DataHubGraphQLParam.INVOICE_EXTERNAL_ID}: String
    $${DataHubGraphQLParam.INVOICE_ITEM_EXTERNAL_ID}: String
    $${DataHubGraphQLParam.PAYMENT_COMPLETED_START_TIME_MS}: Float
    $${DataHubGraphQLParam.PAYMENT_COMPLETED_END_TIME_MS}: Float
    $${DataHubGraphQLParam.CONTRACT_START_START_TIME_MS}: Float
    $${DataHubGraphQLParam.CONTRACT_START_END_TIME_MS}: Float
    $${DataHubGraphQLParam.VENDOR}: String
    $${DataHubGraphQLParam.CUSTOMER_ID}: String
    $${DataHubGraphQLParam.SORT_KEY}: String
    $${DataHubGraphQLParam.SORT_DIRECTION}: String
    $${DataHubGraphQLParam.PAGE}: Float
    $${DataHubGraphQLParam.PAGE_SIZE}: Float
  ) {
    dataHubTable(
      entityId: $entityId,
      ${DataHubGraphQLParam.ID}: $${DataHubGraphQLParam.ID}
      ${DataHubGraphQLParam.IMPORT_START_TIME_MS}: $${DataHubGraphQLParam.IMPORT_START_TIME_MS}
      ${DataHubGraphQLParam.IMPORT_END_TIME_MS}: $${DataHubGraphQLParam.IMPORT_END_TIME_MS}
      ${DataHubGraphQLParam.INVOICE_ISSUED_START_TIME_MS}: $${DataHubGraphQLParam.INVOICE_ISSUED_START_TIME_MS}
      ${DataHubGraphQLParam.INVOICE_ISSUED_END_TIME_MS}: $${DataHubGraphQLParam.INVOICE_ISSUED_END_TIME_MS}
      ${DataHubGraphQLParam.INVOICE_DUE_START_TIME_MS}: $${DataHubGraphQLParam.INVOICE_DUE_START_TIME_MS}
      ${DataHubGraphQLParam.INVOICE_DUE_END_TIME_MS}: $${DataHubGraphQLParam.INVOICE_DUE_END_TIME_MS}
      ${DataHubGraphQLParam.INVOICE_EXTERNAL_ID}: $${DataHubGraphQLParam.INVOICE_EXTERNAL_ID}
      ${DataHubGraphQLParam.INVOICE_ITEM_EXTERNAL_ID}: $${DataHubGraphQLParam.INVOICE_ITEM_EXTERNAL_ID}
      ${DataHubGraphQLParam.PAYMENT_COMPLETED_START_TIME_MS}: $${DataHubGraphQLParam.PAYMENT_COMPLETED_START_TIME_MS}
      ${DataHubGraphQLParam.PAYMENT_COMPLETED_END_TIME_MS}: $${DataHubGraphQLParam.PAYMENT_COMPLETED_END_TIME_MS}
      ${DataHubGraphQLParam.CONTRACT_START_START_TIME_MS}: $${DataHubGraphQLParam.CONTRACT_START_START_TIME_MS}
      ${DataHubGraphQLParam.CONTRACT_START_END_TIME_MS}: $${DataHubGraphQLParam.CONTRACT_START_END_TIME_MS}
      ${DataHubGraphQLParam.VENDOR}: $${DataHubGraphQLParam.VENDOR}
      ${DataHubGraphQLParam.CUSTOMER_ID}: $${DataHubGraphQLParam.CUSTOMER_ID}
      ${DataHubGraphQLParam.SORT_KEY}: $${DataHubGraphQLParam.SORT_KEY}
      ${DataHubGraphQLParam.SORT_DIRECTION}: $${DataHubGraphQLParam.SORT_DIRECTION}
      ${DataHubGraphQLParam.PAGE}: $${DataHubGraphQLParam.PAGE}
      ${DataHubGraphQLParam.PAGE_SIZE}: $${DataHubGraphQLParam.PAGE_SIZE}
    ) {
      pageCount
      currentPage
      customerIdFiltered
      timezoneLocation
      rows {
        ...TransactionCellRowFragment
      }
      highlights {
        ...TableHighlightFragment
      }
      pageSize
    }
  }
`;

const supportedFilters = [
  {
    label: 'ID',
    isDateRange: false,
    filters: [DataHubURLParam.ID],
  },
  {
    label: 'Source',
    isDateRange: false,
    filters: [DataHubURLParam.VENDOR],
  },
  {
    label: 'Customer',
    isDateRange: false,
    filters: [DataHubURLParam.CUSTOMER_ID],
  },
  {
    label: 'Imported',
    isDateRange: true,
    filters: [DataHubURLParam.IMPORT_START_TIME_MS, DataHubURLParam.IMPORT_END_TIME_MS],
  },
  {
    label: 'Invoiced',
    isDateRange: true,
    filters: [DataHubURLParam.INVOICE_ISSUED_START_TIME_MS, DataHubURLParam.INVOICE_ISSUED_END_TIME_MS],
  },
  {
    label: 'Invoiced due',
    isDateRange: true,
    filters: [DataHubURLParam.INVOICE_DUE_START_TIME_MS, DataHubURLParam.INVOICE_DUE_END_TIME_MS],
  },
  {
    label: 'Paid',
    isDateRange: true,
    filters: [DataHubURLParam.PAYMENT_COMPLETED_START_TIME_MS, DataHubURLParam.PAYMENT_COMPLETED_END_TIME_MS],
  },
  {
    label: 'Contract starts',
    isDateRange: true,
    filters: [DataHubURLParam.CONTRACT_START_START_TIME_MS, DataHubURLParam.CONTRACT_START_END_TIME_MS],
  },
  {
    label: 'Source ID',
    isDateRange: false,
    filters: [DataHubURLParam.INVOICE_EXTERNAL_ID],
  },
  {
    label: 'Source Line Item ID',
    isDateRange: false,
    filters: [DataHubURLParam.INVOICE_ITEM_EXTERNAL_ID],
  },
];

const DataHubCard: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [params, setSearchParams] = useSearchParams();
  const { isRealCustomerCompany, currentCompany } = useCompanyContext();

  const mappedNumberQueryParams = Object.keys(DataHubNumberGraphQLParamToURLParam).reduce<{
    [key in DataHubGraphQLParam]?: number | null;
  }>((obj, key) => {
    const graphQLQueryField = key as DataHubGraphQLParam;
    obj[graphQLQueryField] =
      Number(params.get(DataHubNumberGraphQLParamToURLParam[graphQLQueryField] as DataHubURLParam)) || null;
    return obj;
  }, {});
  const mappedStringQueryParams = Object.keys(DataHubStringGraphQLParamToURLParam).reduce<{
    [key in DataHubGraphQLParam]?: string | null;
  }>((obj, key) => {
    const graphQLQueryField = key as DataHubGraphQLParam;
    obj[graphQLQueryField] = params.get(DataHubStringGraphQLParamToURLParam[graphQLQueryField] as DataHubURLParam);
    return obj;
  }, {});
  const queryParams = {
    ...mappedNumberQueryParams,
    ...mappedStringQueryParams,
    page: 1,
    entityId: currentCompany.id,
    page_size: null,
  };

  const replaceSearchParams = (obj: StringToStringMap) => {
    const newParams = new URLSearchParams();
    Object.keys(obj).forEach((k) => {
      newParams.set(k, obj[k]);
    });
    setSearchParams(newParams);
  };

  let sort: Sort | undefined = undefined;
  if (
    params.get(DataHubURLParam.SORT_KEY) &&
    ['asc', 'des'].indexOf(params.get(DataHubURLParam.SORT_DIRECTION) || '') >= 0
  ) {
    sort = {
      key: (params.get(DataHubURLParam.SORT_KEY) || '') as SupportedSortKeys,
      direction:
        params.get(DataHubURLParam.SORT_DIRECTION) === SortDirections.ASC ? SortDirections.ASC : SortDirections.DESC,
    };
  }

  const dateFilter: DateFilter = {};
  Object.keys(DataHubURLParamToGraphQLParam).forEach((filterType) => {
    const val = params.get(filterType);
    if (val) {
      const dateField = DataHubURLParamToGraphQLParam[filterType as DataHubURLParam];
      if (dateField) {
        dateFilter[dateField] = Number(val);
      }
    }
  });

  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useQuery<{
    dataHubTable: DataHubTable;
    currentUser?: UserModel;
  }>(QUERY, {
    variables: queryParams,
    // no-cache ensures that any combination of filters and sort directions will always refetch
    fetchPolicy: 'no-cache',
  });

  const { isScribd } = useCompanyContext();

  const [isTablePaging, updateIsTablePaging] = useState(false);

  if (error) {
    return <div className={sharedClasses.main}>Error: {error.message}</div>;
  }

  const timezoneLocation = data?.dataHubTable?.timezoneLocation;
  const columnNames = getDataHubTableColumnNamesForDisplay(
    (data?.currentUser?.company?.baseCurrency as TCurrency) || TCurrency.USD
  );
  const pagerVariables = {
    currentPage: data?.dataHubTable?.currentPage || 1,
    pageCount: data?.dataHubTable?.pageCount || 1,
    pageSize: data?.dataHubTable?.pageSize || 50,
    refetchPage: (page: number, pageSize: number) => {
      updateIsTablePaging(true);
      refetch({
        ...queryParams,
        page,
        page_size: pageSize,
      }).then(() => {
        updateIsTablePaging(false);
      });
    },
  };

  const columns = data?.dataHubTable?.rows?.[0]
    ? [...getColumns(data.dataHubTable.rows[0], true, sharedClasses, timezoneLocation)]
    : [];
  columns?.forEach((col) => {
    let accessorParams: TabulatorTypes.CustomAccessorParams | undefined;
    switch (col.title) {
      case columnNames.SOURCE_ID:
        accessorParams = { clickType: 'stringFilterHeaderClick' };
        break;
      case columnNames.IMPORTED_AT:
      case columnNames.CONTRACT_START:
      case columnNames.INVOICED_AT:
      case columnNames.PAID_AT:
        accessorParams = { clickType: 'dateFilterHeaderClick' };
        break;
    }
    if (isScribd && [columnNames.PAID_AT, columnNames.IMPORTED_AT].includes(col.title)) {
      accessorParams = {};
    }
    col.accessorParams = { ...col.accessorParams, ...accessorParams };
    col.resizable = col.title !== columnNames.SOURCE;
  });

  const flattenedData = data?.dataHubTable?.rows ? flattenData(data?.dataHubTable.rows) : [];

  const highlights: TableHighlightFragmentFragment[] = data
    ? data.dataHubTable.highlights || []
    : [{ label: '', type: '', value: '' }];

  const options: TabulatorTypes.Options = {
    layout: 'fitData',
    data: flattenedData,
    sortMode: 'remote',
    columnDefaults: {
      title: '',
      headerSortTristate: true,
    },
    // use initialSort to account for querystring sort params
    initialSort:
      sort?.key && sort?.direction
        ? [
            {
              column: sort.key,
              dir: sort.direction === SortDirections.ASC ? 'asc' : 'desc',
            } as TabulatorTypes.Sorter,
          ]
        : undefined,
  };

  return (
    <div className={sharedClasses.main}>
      <Banner
        highlights={highlights.map((highlight) => ({
          header: highlight.label || '',
          isLoading,
          value: highlight.value || '',
        }))}
        showSearchBar
      />
      <div className={sharedClasses.contentWrapper}>
        {isLoading ? (
          <div className={sharedClasses.contentLoaderContainer}>
            <LoaderAnimation height={80} />
          </div>
        ) : (
          <>
            <div className={sharedClasses.contentHeaderWrapper}>
              <div className={sharedClasses.contentHeader}>
                {isRealCustomerCompany ? (
                  <div className="card-tabs">
                    <div className="card-tab card-tab-selected">
                      <span>Data Hub: Line Items</span>
                    </div>
                  </div>
                ) : (
                  <MenuDropdown
                    horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                    width={280}
                    items={[
                      {
                        label: 'Numeral Data',
                        href: '/app/data-hub',
                      },
                      {
                        label: 'Raw Data',
                        href: '/app/data-hub/raw',
                        items: [
                          {
                            label: 'Stripe Europe (Stripe)',
                            href: '/app/data-hub/raw/stripe',
                            items: [
                              {
                                label: 'Line Items',
                                href: '/app/data-hub/raw/stripe/line-items',
                              },
                              {
                                label: 'Invoices',
                                href: '/app/data-hub/raw/stripe/invoices',
                              },
                              {
                                label: 'Payments',
                                href: '/app/data-hub/raw/stripe/payments',
                              },
                              {
                                label: 'Refunds',
                                href: '/app/data-hub/raw/stripe/refunds',
                              },
                              {
                                label: 'Subscriptions',
                                href: '/app/data-hub/raw/stripe/subscriptions',
                              },
                              {
                                label: 'Customers',
                                href: '/app/data-hub/raw/stripe/customers',
                              },
                              {
                                label: 'Products',
                                href: '/app/data-hub/raw/stripe/products',
                              },
                            ],
                          },
                          {
                            label: 'Stripe USA (Stripe)',
                            href: '/app/data-hub/raw/stripe',
                            items: [
                              {
                                label: 'Line Items',
                                href: '/app/data-hub/raw/stripe/line-items',
                              },
                              {
                                label: 'Invoices',
                                href: '/app/data-hub/raw/stripe/invoices',
                              },
                              {
                                label: 'Payments',
                                href: '/app/data-hub/raw/stripe/payments',
                              },
                              {
                                label: 'Refunds',
                                href: '/app/data-hub/raw/stripe/refunds',
                              },
                              {
                                label: 'Subscriptions',
                                href: '/app/data-hub/raw/stripe/subscriptions',
                              },
                              {
                                label: 'Customers',
                                href: '/app/data-hub/raw/stripe/customers',
                              },
                              {
                                label: 'Products',
                                href: '/app/data-hub/raw/stripe/products',
                              },
                            ],
                          },
                          {
                            label: 'Paypal (Paypal)',
                            href: '/app/data-hub/raw/paypal',
                            items: [
                              {
                                label: 'Invoices',
                                href: '/app/data-hub/raw/paypal/invoices',
                              },
                              {
                                label: 'Payments',
                                href: '/app/data-hub/raw/paypal/payments',
                              },
                            ],
                          },
                          {
                            label: 'ChargeBee 1 (Chargebee)',
                            href: '/app/data-hub/raw/recurly',
                            items: [
                              {
                                label: 'Customers',
                                href: '/app/data-hub/raw/recurly/customers',
                              },
                              {
                                label: 'Subscriptions',
                                href: '/app/data-hub/raw/recurly/subscriptions',
                              },
                              {
                                label: 'Invoices',
                                href: '/app/data-hub/raw/recurly/invoices',
                              },
                              {
                                label: 'Payments',
                                href: '/app/data-hub/raw/recurly/payments',
                              },
                              {
                                label: 'Plans',
                                href: '/app/data-hub/raw/recurly/plans',
                              },
                            ],
                          },
                        ],
                      },
                    ]}
                  >
                    Viewing: Numeral Data
                  </MenuDropdown>
                )}
              </div>
              <div className={sharedClasses.contentHeaderUtils}>
                <ContentFilters
                  customerIdFiltered={data?.dataHubTable?.customerIdFiltered}
                  searchParams={params}
                  replaceSearchParams={replaceSearchParams}
                  supportedFilters={supportedFilters}
                />
                {isRealCustomerCompany ? (
                  <ExportButton type={TCsvExportType.DATA_HUB} searchParams={params} enabled />
                ) : undefined}
              </div>
            </div>
            {isTablePaging ? (
              <div className={sharedClasses.contentLoaderContainer}>
                <LoaderAnimation height={80} />
              </div>
            ) : (
              <div className={sharedClasses.content}>
                <DataGrid
                  className={classes.dataHubTable}
                  columns={columns}
                  options={options}
                  shouldEnablePager={true}
                  pagerVariables={pagerVariables}
                  shouldEnableUrlFiltering={true}
                  shouldEnableUrlSorting={true}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DataHubCard;
