import { parseCompanyName } from '../features/app/helpers/parseCompanyName.ts';

export const getHomePageRoute = (companyName = '') => {
  const parsedCompany = parseCompanyName(companyName);
  return {
    url: `${getBaseRoute(companyName, 'revenue')}`,
    companyName: parsedCompany,
  };
};

export const getBaseRoute = (companyName = '', childRoute = '') => {
  const parsedCompany = parseCompanyName(companyName);
  const baseRoute = `/app/${parsedCompany}`;
  return childRoute ? `${baseRoute}/${childRoute}` : baseRoute;
};
