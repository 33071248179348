import React, { useState } from 'react';

import * as staticDimensions from './constants/dimensions.ts';
import { AnimatedWrapper } from '../sideBar/components/animatedWrapper/index.ts';
import { ApplySettingControl } from './components/applySettingControl/ApplySettingControl.tsx';
import { CollapsableMenuItems } from './components/collapsableMenuItems';
import { Header } from '../sideBar/components/header/Header.tsx';
import { PivotDropDown } from './components/pivotDropDown/PivotDropDown.tsx';
import { SidebarWidth } from '../sideBar/constants/sideBarWidth.ts';
import { Spacer } from '../../components/Spacer.tsx';
import { UpsertPivotSettingModal } from './components/upsertPivotSettingModal/UpsertPivotSettingModal.tsx';
import { useCompanyContext } from '../../context/CompanyContext.tsx';
import { useCurrentSessionQuery } from '../sideBar/hooks/useCurrentSessionQuery.ts';
import { useRevenueTableContext } from '../../context/RevenueTableContext.tsx';
import { useSideBarContext } from '../sideBar/providers/SideBarProvider.tsx';
import { useStyles } from './styles.ts';
import { CompanyModel, HoldingCompanyModel, useGetAllPivotsQuery } from '../../generated/graphql.tsx';
import {
  getDemoCompanyEntities,
  getEntities,
} from '../../components/NavigationSidebar/helpers/getDemoCompanyEntities.ts';

export const PivotSetting = () => {
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const { isHealthie, currentCompany, isRealCustomerCompany, onSetCurrentCompany, companyData } = useCompanyContext();
  const [showLoader, setShowLoader] = useState(false);
  const { showSideBarType, onResetSideBar, onSetIsDragging } = useSideBarContext();
  const { onSetCustomPivot, onSavePivot } = useRevenueTableContext();
  const { data } = useCurrentSessionQuery();
  const classes = useStyles();
  const isCollapsed = showSideBarType !== 'PIVOT';

  const { refetch } = useGetAllPivotsQuery({
    fetchPolicy: 'cache-only',
  });

  const onSavePivotHandler = async (name: string, isDefault: boolean) => {
    setShowLoader(true);
    try {
      await onSavePivot(name, isDefault);
      await refetch();
      setShowUpsertModal(false);
    } finally {
      setShowLoader(false);
    }
  };

  const dimensions = isHealthie ? staticDimensions.healthie : staticDimensions.defaultDimensions;

  const onOptionSelect = (entity: string) => {
    localStorage.setItem('multiEntity', entity);
    window.location.reload();
  };

  const onEntityChange = (selectedCompany: CompanyModel) => {
    if (currentCompany.name === selectedCompany.name) {
      return;
    }
    onSetCurrentCompany(selectedCompany);
  };

  const onClose = () => {
    onResetSideBar();
    onSetCustomPivot(false);
  };

  const onAddPivot = () => setShowUpsertModal(true);

  const entityItems = !isRealCustomerCompany
    ? getDemoCompanyEntities(currentCompany.name, onOptionSelect)
    : getEntities(companyData?.holdingCompany as HoldingCompanyModel, onEntityChange);

  return (
    <AnimatedWrapper width={SidebarWidth.PIVOT} isCollapsed={isCollapsed} hasAnimation={true}>
      <div className={classes.wrapper}>
        <Header
          company={currentCompany?.name ?? ''}
          name={data?.currentSession.firstName ?? ''}
          items={entityItems}
          defaultDDValue=""
        />
        <Spacer height={40} />
        <ApplySettingControl onClose={onClose} onSave={onAddPivot} onSaveAs={onAddPivot} />
        <Spacer height={8} />
        <PivotDropDown />
        <Spacer height={8} />
        <div className={classes.menuItems}>
          <CollapsableMenuItems menuItems={dimensions as any} onSetIsDragging={onSetIsDragging} />
        </div>
      </div>
      {showUpsertModal && (
        <UpsertPivotSettingModal
          isLoading={showLoader}
          onClose={() => setShowUpsertModal(!showUpsertModal)}
          onSave={onSavePivotHandler}
        />
      )}
    </AnimatedWrapper>
  );
};
