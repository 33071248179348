import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  loaderContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
