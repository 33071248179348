import { createUseStyles } from 'react-jss';

import Colors from '../../components/common/Colors.tsx';
import { borderStyle } from '../../components/common/TabbedContainers.tsx';

export const useStyles = createUseStyles({
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  column: {
    borderRight: borderStyle,
    flex: '1',
    padding: '15px',

    '&:last-child': {
      borderRight: '0',
    },

    '& ul': {
      margin: '0',
      padding: '0 0 0 15px',
      listStyleType: 'circle',

      '& li': {
        margin: '0',
        padding: '0 0 5px 0',
      },
    },

    '&::before': {
      color: Colors.MEDIUM_GRAY,
      content: 'attr(data-header)',
      display: 'block',
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },

  columnGreyedOut: {
    backgroundColor: Colors.FAINT_GRAY,
    minWidth: '50%',
    maxWidth: '50%',
  },

  tabHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',

    '& span': {
      display: 'block',
    },
  },
  cellPill: {
    letterSpacing: '0',
    marginLeft: '5px',
    padding: '4px',
  },

  rawJSONContainer: {
    overflowY: 'scroll',
    padding: '10px 5px',
    minHeight: '200px',
  },
});
