import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';

import { DataGridRow } from '../DataGridTypes.ts';
import { parseCompanyName } from '../../../features/app/helpers/parseCompanyName.ts';
import { useCompanyContext } from '../../../context/CompanyContext';

export const useLinkFormatter = () => {
  const { currentCompany } = useCompanyContext();

  const linkFormatter = (cell: Tabulator.CellComponent) => {
    const data = cell.getData() as DataGridRow;
    const label = cell.getValue();
    const colDef = cell?.getColumn()?.getDefinition();
    const cellElem = cell.getElement();

    const cellCssClass = data?._cssClass?.[cell.getField()];
    if (cellCssClass) {
      cellElem.classList.add(cellCssClass);
    }

    const value = data?._cellData?.[cell.getField()]?.url;
    if (value) {
      const el = document.createElement('a');
      const href = value ? `/app/${parseCompanyName(currentCompany.name)}${value}` : '#';
      el.setAttribute('href', href);
      el.setAttribute('target', '_blank');
      el.innerText = label;

      const cssClass = (colDef?.formatterParams as Tabulator.JSONRecord)?.cssClass;
      if (cssClass) {
        el.classList.add(cssClass.toString());
      }

      el.addEventListener('click', (e) => {
        e.preventDefault();
        window.open(href);
      });

      return el;
    }

    return label;
  };

  return linkFormatter;
};
