import React from 'react';

import classnames from 'classnames';

import KeyValueList from '../../../../components/common/KeyValueList.tsx';
import { Payout } from '../../../../generated/graphql.tsx';
import PrettyJSON from '../../../../components/common/PrettyJSON.tsx';
import SectionWithHeader from '../../../../components/common/SectionWithHeader.tsx';
import TabbedContainers from '../../../../components/common/TabbedContainers.tsx';
import { getJSONHeight } from '../../utils/getJSONHeight.ts';
import { useSharedStyles } from '../../../../utils/CssUtil.ts';
import { useStyles } from '../../styles.ts';

type PayoutDetailsProps = {
  payout: Payout;
};

export const PayoutDetails = ({ payout }: PayoutDetailsProps) => {
  const sharedClasses = useSharedStyles();

  const classes = useStyles();

  const customerJSONHeightPx = getJSONHeight(payout?.rawJSON ? Object.keys(payout.rawJSON).length : 0);

  return (
    <>
      <div className={sharedClasses.contentHeaderWrapper}>
        <div className={sharedClasses.contentHeader}>
          <div className={sharedClasses.cardTabs}>
            <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
              <span>Line Item: {payout?.id}</span>
            </div>
          </div>
        </div>
        <div className={sharedClasses.contentHeaderUtils}></div>
      </div>
      <SectionWithHeader header="payout" isCollapsible isCollapsedInitially={false} bodyPaddingPx={0}>
        <div className={classes.columnContainer}>
          <div className={classes.column} data-header="Numeral Data">
            <KeyValueList
              keyValuePairs={[
                {
                  key: 'Numeral Id',
                  value: payout?.id || 'N/A',
                },
                {
                  key: 'Description',
                  value: payout?.description || 'N/A',
                },
                {
                  key: 'Payment Processor',
                  value: payout?.disbursingVendor || 'N/A',
                },
                {
                  key: 'Payout Amount',
                  value: payout?.formattedAmount ?? 0,
                },
                {
                  key: 'Bank',
                  value: payout?.receivingVendor ?? 'N/A',
                },
                {
                  key: 'Type',
                  value: payout?.direction ?? 'N/A',
                },
              ]}
            />
          </div>
          <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
            <TabbedContainers
              tabContentClass={classes.rawJSONContainer}
              tabContentStyle={{ height: customerJSONHeightPx }}
              tabs={[
                {
                  header: (
                    <div className={classes.tabHeader}>
                      <span>Payout</span>
                      <span className={classnames(sharedClasses.cellPill, classes.cellPill)}>
                        {payout.disbursingVendor}
                      </span>
                    </div>
                  ),
                  content: <PrettyJSON object={payout?.rawJSON || {}} />,
                },
              ]}
            />
          </div>
        </div>
      </SectionWithHeader>
    </>
  );
};
