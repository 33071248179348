import React, { useEffect, useState } from 'react';

import Lottie from 'react-lottie-player';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import { gql } from '@apollo/client';

import Colors from '../../common/Colors';
import LoaderAnimation from '../../common/LoaderAnimation';
import Modal from '../../common/Modal';
import getAssetPath from '../../../utils/AssetPathUtil';
import { getHomePageRoute } from '../../../utils/GetHomePageRoute.ts';
import success_animation from '../../../../public/animations/success_animation.json';
import { useCompanyContext } from '../../../context/CompanyContext.tsx';
import { useRevenueTableContext } from '../../../context/RevenueTableContext.tsx';
import { useSetLockMonthMutation } from '../../../generated/graphql';
import FormButton, { FormButtonStyle } from '../../common/FormButton';
import FormatUtil, { DateFormatLongMonthYear } from '../../../../src/utils/FormatUtil';
import { TLedgerDirection, TLedgerDirectionAbbreviated } from '../../../../src/types/LedgerTypes.ts';

const useStyles = createUseStyles({
  modalHeaderTitle: {
    margin: '12px 0',
  },
  expandFull: {
    width: '100%',
  },
  innerContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  modalBodyLock: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  modalBodyAnimation: {
    width: 150,
    height: 150,
    position: 'absolute',
    left: 185,
  },
  modalBodyLockLabel: {
    padding: '20px 0',
  },
  modalBodyDefault: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '19.6px',
    color: '#8597B4',
  },
  modalIconHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: '#8597B4',
      fontFamily: 'Helvetica Neue',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '0.1px',
    },
  },
  modalLogoImage: {
    height: '12px',
    // width: '10px',
    flexShrink: 0,
    padding: '5px 3px',
  },
  modalBodyDetails: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    flex: 1,
    overflowY: 'auto',
  },
  modalBodyDetailsItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  modalBodyDetailsItemBox: {
    width: 230,
    height: 45,
    flexShrink: 0,
    borderRadius: 3,
    border: '1px solid #CAD5E8',
  },
  modalBodyDetailsItemBoxValue: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '8px 9px 7px 10px',
    '& label': {
      width: '100%',
      color: '#8597B4',
      fontSize: '10px',
      fontWeight: 400,
      letterSpacing: '0.1px',
    },
    '& span': {
      color: '#22314A',
      fontFamily: 'Helvetica Neue',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.12px',
    },
  },
  modalBodyDetailsItemArrow: {
    color: '#CCC',
    fontFamily: 'Helvetica Neue',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    width: 16,
    paddingLeft: 30,
    paddingRight: 22,
  },
  modalBodyDetailsItemValue: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 211,
    margin: '8px 9px 7px 10px',
    '& label': {
      width: '100%',
      color: '#8597B4',
      fontSize: '10px',
      fontWeight: 400,
      letterSpacing: '0.1px',
    },
    '& span': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      width: '80%',

      color: '#22314A',
      fontFamily: 'Helvetica Neue',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.12px',
      '&.credit': {
        justifyContent: 'flex-end',
      },
      '&.debit': {
        justifyContent: 'flex-start',
      },
    },
  },
  modalFooter: {
    display: 'flex',
    flexFlow: 'row-reverse',
    justifyContent: 'space-around',
    // gap: 125,
    gap: 315,
    marginTop: '10px',
    '& > *': {
      '&:first-child': {
        marginLeft: '0',
      },
    },
  },
});

gql`
  mutation SetLockMonth($yearMonth: String!) {
    setLockMonth(yearMonth: $yearMonth) {
      yearMonth
    }
  }
`;

type DataGridColumnLockMonthWithGlModalProps = {
  lockMonthStr: string | undefined;
  toggleVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const DataGridColumnLockMonthWithGlModal: React.FunctionComponent<DataGridColumnLockMonthWithGlModalProps> = ({
  lockMonthStr,
  toggleVisibility,
}: DataGridColumnLockMonthWithGlModalProps) => {
  const { currentCompany } = useCompanyContext();
  const classes = useStyles();

  const [makeMutation, { loading: isMutationLoading, error: mutationErrorStr }] = useSetLockMonthMutation();

  const { aggregatedMappedAccounts, loadPushToGlPreview, isLoading: tableLoading } = useRevenueTableContext();

  useEffect(() => {
    if (lockMonthStr) {
      loadPushToGlPreview(lockMonthStr);
    }
  }, [loadPushToGlPreview, lockMonthStr]);

  const [lockMonthSuccessMessage, updateLockMonthSuccessMessage] = useState<string | undefined>();
  const [lockMonthError, updateLockMonthError] = useState<string | undefined>();

  let displayLockMonthStr: string | undefined = undefined;
  if (lockMonthStr) {
    displayLockMonthStr = moment(FormatUtil.monthStrToDate(lockMonthStr)).format(DateFormatLongMonthYear);
  }

  const getTLedgerDirectionAbbreviated = (direction?: string | null) => {
    switch (direction as TLedgerDirection) {
      case TLedgerDirection.CREDIT:
        return TLedgerDirectionAbbreviated.CREDIT;
      default:
        return TLedgerDirectionAbbreviated.DEBIT;
    }
  };

  const hasError = mutationErrorStr || lockMonthError;
  const isLoading = tableLoading || isMutationLoading;

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => toggleVisibility(false)}
      contentLabel={`Lock ${displayLockMonthStr}?`}
      style={{
        content: {
          overflow: 'hidden',
          maxHeight: '700px',
          position: 'absolute',
          top: '5%',
          left: '30%',
          maxWidth: '600px',
          padding: '25px 33px',
          borderRadius: 5,
        },
      }}
    >
      <div className={classes.innerContainer}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h3 className={classes.modalHeaderTitle}>Lock {displayLockMonthStr}</h3>
            {hasError ? (
              <p style={{ color: Colors.WARNING_RED }}>{String(lockMonthError || mutationErrorStr)}</p>
            ) : null}
            {isLoading && (
              <div
                style={{
                  marginRight: 50,
                }}
              >
                <LoaderAnimation height={40} />
              </div>
            )}
          </div>
          <hr />
          {lockMonthSuccessMessage && !isLoading && !hasError ? (
            <div className={classes.modalBodyLock}>
              <Lottie animationData={success_animation} play loop={false} className={classes.modalBodyAnimation} />
              <span className={classes.modalBodyLockLabel}>{lockMonthSuccessMessage}</span>
            </div>
          ) : (
            <p className={classes.modalBodyDefault}>
              Summary JEs will be fed directly into your NetSuite chart of account. Any adjustments to this period will
              automatically roll forward to the next open period. <strong>This action cannot be undone.</strong>
            </p>
          )}
          <div className={classes.modalIconHeader}>
            <span style={{ width: 315 }}>
              <img className={classes.modalLogoImage} src={`${getAssetPath()}/images/logo_small.png`} />
              Numeral
            </span>
            {/* 
          <span style={{ width: 180 }}>
            <img className={classes.modalLogoImage} src={`${getAssetPath()}/images/icon_netsuite.png`} />
            NetSuite Account XXXXXX
          </span>
          <span>
            <img className={classes.modalLogoImage} src={`${getAssetPath()}/images/icon_csv.svg`} />
            Export
            <ExportButton
              type={TCsvExportType.REVENUE_DASHBOARD}
              url={`/download/csv-revenue?st=${startTime?.getTime()}&et=${endTime?.getTime()}`}
            /> 
          </span>

          */}
          </div>
          <hr />
        </div>

        <div className={classes.modalBodyDetails}>
          {tableLoading || !aggregatedMappedAccounts ? (
            <div
              style={{
                position: 'absolute',
                top: '52%',
                left: '42%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <LoaderAnimation height={80} />
            </div>
          ) : (
            aggregatedMappedAccounts.map((map, idx) => (
              <div key={idx} className={classes.modalBodyDetailsItem}>
                <div className={classes.modalBodyDetailsItemBox}>
                  <div className={classes.modalBodyDetailsItemBoxValue}>
                    <label>{map.internalAccountName}</label>
                    <span onClick={() => alert(map.rowLabel)}>
                      {`${map.rowLabel}${String(map.amount)}`.length > 30
                        ? map.rowLabel.substring(0, 20) + '...'
                        : map.rowLabel}
                    </span>
                    <span>{map.amount}</span>
                  </div>
                </div>
                <span className={classes.modalBodyDetailsItemArrow}>→</span>
                <div className={classes.modalBodyDetailsItemValue}>
                  <label>{map.externalAccountName}</label>
                  <span className={`${map.direction || ''}`}>
                    {`${getTLedgerDirectionAbbreviated(map.direction)} ${map.amount.replace('-', '')}`}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
        <hr className={classes.expandFull} />
        {!isLoading && (
          <div className={classes.modalFooter}>
            {lockMonthSuccessMessage !== 'Success!' ? (
              <FormButton
                style={FormButtonStyle.ACCENTED}
                isDisabled={isMutationLoading}
                onClick={() => {
                  updateLockMonthError(undefined);
                  makeMutation({ variables: { yearMonth: lockMonthStr || '' } }).then((resp) => {
                    const defaultError = 'Unable to lock month, try again later';
                    if (resp.errors) {
                      updateLockMonthError(defaultError);
                    } else if (resp.data?.setLockMonth?.length) {
                      updateLockMonthSuccessMessage('Success!');
                    } else {
                      updateLockMonthError(defaultError);
                    }
                  });
                }}
                width={130}
              >
                Lock Month
              </FormButton>
            ) : (
              <FormButton
                style={FormButtonStyle.PLAIN}
                isDisabled={isLoading}
                onClick={() => {
                  updateLockMonthError(undefined);
                  updateLockMonthSuccessMessage(undefined);
                  toggleVisibility(false);
                  // refresh page
                  document.location.pathname = getHomePageRoute(currentCompany.name).url;
                }}
                width={130}
              >
                Close
              </FormButton>
            )}
            {lockMonthSuccessMessage === 'Success!' ? null : (
              <FormButton
                style={FormButtonStyle.PLAIN}
                onClick={() => toggleVisibility(false)}
                width={76}
                extraStyle={{ border: 'none' }}
              >
                Go Back
              </FormButton>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DataGridColumnLockMonthWithGlModal;
