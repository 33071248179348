import React from 'react';

import { Outlet } from 'react-router-dom';

import { CompanyProvider } from '../../context/CompanyContext.tsx';
import { RevenueTableProvider } from '../../context/RevenueTableContext.tsx';
import { SideBar } from '../../layout/SideBar/SideBar';

export const App = () => {
  return (
    <CompanyProvider>
      <RevenueTableProvider>
        <SideBar />
        <Outlet />
      </RevenueTableProvider>
    </CompanyProvider>
  );
};
