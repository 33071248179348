import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '../common/Colors.tsx';
import KeyValueList from '../common/KeyValueList';
import SectionNavHeader from '../SectionNavHeader';
import SectionWithHeader from '../common/SectionWithHeader';
import { Payout, TransactionRecognitionTableInvoiceFragmentFragment } from '../../generated/graphql';
import { useSharedStyles } from '../../utils/CssUtil';

const useStyles = createUseStyles({
  overviewSection: {
    '& *': {
      fontSize: '13px',
    },
    '& a, & a:hover, & a:visited': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dashed',
    },
  },
  triggeredRule: {
    backgroundColor: Colors.LIGHT_GRAY,
    boxSizing: 'border-box',
    padding: '15px',
    borderRadius: '3px',
    lineHeight: 1.3,
    marginBottom: '10px',
    width: '50%',

    '&:last-child': {
      marginBottom: 0,
    },

    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
      textDecoration: 'underline dashed',
    },
  },
});

type PaymentRecognitionScheduleCardCashProps = {
  isRealCustomerCompany?: boolean;
  payout?: Payout & { transactionId: string };
  reportSettingsExclude?: string[] | null;
  shouldSkipEventsSection?: boolean;
  isCashReconciliationEnabled?: boolean;
  timezoneLocation?: string;
  companyName?: string;
  invoice?: TransactionRecognitionTableInvoiceFragmentFragment;
  transactionId?: string;
};
const PaymentRecognitionScheduleCardCashTransaction: React.FunctionComponent<
  PaymentRecognitionScheduleCardCashProps
> = ({
  isRealCustomerCompany,
  payout,
  reportSettingsExclude,
  shouldSkipEventsSection,
  isCashReconciliationEnabled,
  timezoneLocation,
  companyName,
  invoice,
  transactionId,
}: PaymentRecognitionScheduleCardCashProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  if (!invoice) {
    return null;
  }

  let cashContainer: ReactElement | undefined = undefined;

  cashContainer = (
    <div style={{ marginTop: '10px ' }}>
      <div className={sharedClasses.contentHeaderWrapper}>
        <SectionNavHeader
          sections={[
            {
              label: 'Cash Transaction',
            },
          ]}
        />
        <div className={sharedClasses.contentHeaderUtils} />
      </div>
      {invoice.paymentApplications?.map((pa, idx) => {
        const keyValuePairs = [
          {
            key: 'Source',
            value: pa.paymentEntity.vendor || 'N/A',
          },
          {
            key: 'Paid On Date',
            value: pa.cashTransactionEntity?.paidOnDate || 'N/A',
          },
          {
            key: 'Settled On Date',
            value: pa.cashTransactionEntity?.settledOnDate || 'N/A',
          },
          {
            key: `Total Paid (${pa.entity.baseCurrency || pa.entity.currency})`,
            value:
              pa.entity.formattedAmountBaseCurrency +
              (pa.entity.baseCurrency && pa.entity.currency !== pa.entity.baseCurrency
                ? ` (${pa.entity.formattedAmount})`
                : ''),
          },
          {
            key: `PSP Fee (${pa.entity.baseCurrency || pa.entity.currency})`,
            value: pa.entity.baseCurrencyTransactionFee,
          },
          {
            key: `Tax Payable (${pa.entity.baseCurrency || pa.entity.currency})`,
            value: pa.entity.formattedAmountForTaxPayableToDate,
          },
          {
            key: `Net Setttled Amount (${pa.entity.baseCurrency || pa.entity.currency})`,
            value: pa.cashTransactionEntity?.formattedBaseCurrencyNetAmountSettled || 'N/A',
          },
        ];
        return (
          <div className={classes.overviewSection} key={`cashTransaction-${transactionId}`}>
            <SectionWithHeader header={`Cash Transaction: ${transactionId}`}>
              <KeyValueList keyValuePairs={keyValuePairs} />
            </SectionWithHeader>
          </div>
        );
      })}
    </div>
  );

  return <>{cashContainer}</>;
};

export default PaymentRecognitionScheduleCardCashTransaction;
