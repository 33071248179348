import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import Colors from '../common/Colors.tsx';
import KeyValueList from '../common/KeyValueList';
import SectionNavHeader from '../SectionNavHeader';
import SectionWithHeader from '../common/SectionWithHeader';
import { TransactionRecognitionTableInvoiceFragmentFragment, VendorCost } from '../../generated/graphql';
import { useSharedStyles } from '../../utils/CssUtil';
import { DateFormatYearMonthDate } from '../../../src/utils/FormatUtil.ts';

const useStyles = createUseStyles({
  overviewSection: {
    '& *': {
      fontSize: '13px',
    },
    '& a, & a:hover, & a:visited': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dashed',
    },
  },
  triggeredRule: {
    backgroundColor: Colors.LIGHT_GRAY,
    boxSizing: 'border-box',
    padding: '15px',
    borderRadius: '3px',
    lineHeight: 1.3,
    marginBottom: '10px',
    width: '50%',

    '&:last-child': {
      marginBottom: 0,
    },

    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
      textDecoration: 'underline dashed',
    },
  },
});

type PaymentRecognitionScheduleCardVendorCostProps = {
  isRealCustomerCompany?: boolean;
  vendorCost?: VendorCost;
  reportSettingsExclude?: string[] | null;
  shouldSkipEventsSection?: boolean;
  isCashReconciliationEnabled?: boolean;
  timezoneLocation?: string;
  companyName?: string;
  invoice?: TransactionRecognitionTableInvoiceFragmentFragment;
  transactionId?: string;
};
const PaymentRecognitionScheduleCardVendorCost: React.FunctionComponent<
  PaymentRecognitionScheduleCardVendorCostProps
> = ({
  isRealCustomerCompany,
  vendorCost,
  reportSettingsExclude,
  shouldSkipEventsSection,
  isCashReconciliationEnabled,
  timezoneLocation,
  companyName,
  invoice,
  transactionId,
}: PaymentRecognitionScheduleCardVendorCostProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  if (!vendorCost) {
    return null;
  }
  const paidOndate = moment(vendorCost.collectedAt);
  if (timezoneLocation) {
    paidOndate.tz(timezoneLocation);
  }

  let refundContainer: ReactElement | undefined = undefined;
  const keyValuePairs = [
    {
      key: 'Source',
      value: vendorCost.vendor || 'N/A',
    },
    {
      key: 'Paid On Date',
      value: paidOndate.format(DateFormatYearMonthDate) || 'N/A',
    },
    {
      key: 'Description',
      value: vendorCost.description || 'N/A',
    },
    {
      key: `Transaction Fee`,
      value: vendorCost.formattedBaseCurrencyAmount,
    },
  ];
  refundContainer = (
    <div style={{ marginTop: '10px ' }}>
      <div className={sharedClasses.contentHeaderWrapper}>
        <SectionNavHeader
          sections={[
            {
              label: 'Vendor Cost',
            },
          ]}
        />
        <div className={sharedClasses.contentHeaderUtils} />
      </div>
      <div className={classes.overviewSection} key={`Vendor Cost-${transactionId}`}>
        <SectionWithHeader header={`Vendor Cost: ${transactionId}`}>
          <KeyValueList keyValuePairs={keyValuePairs} />
        </SectionWithHeader>
      </div>
    </div>
  );

  return <>{refundContainer}</>;
};

export default PaymentRecognitionScheduleCardVendorCost;
