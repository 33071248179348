/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import Modal from 'react-modal';
import { createUseStyles } from 'react-jss';

import Colors from './common/Colors';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import getAssetPath from '../utils/AssetPathUtil';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import {
  CashTransactionReportCsvExportMutationVariables,
  RevenueTransactionReportCsvExportMutationVariables,
  useCashTransactionReportCsvExportMutation,
  useDataHubCsvExportMutation,
  useDepositsReportCsvExportMutation,
  useMissingDataExceptionReportCsvExportMutation,
  useReconReportCsvExportMutation,
  useRevenueTransactionReportCsvExportMutation,
} from '../generated/graphql.tsx';

const CSV_EXPORT_ERROR_MESSAGE = 'Something went wrong when exporting data as CSV. Please try again later';
const CSV_EXPORT_SUCCESS_MESSAGE =
  'We are preparing your CSV for export. You will be notified when it is ready for download';

const useStyles = createUseStyles({
  exportCsv: {
    alignItems: 'center',
    color: Colors.MEDIUM_GRAY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  closeModalBtn: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalSection: {
    marginBottom: '48px',
  },
});

type ExportButtonProps = {
  type: TCsvExportType;
  enabled?: boolean;
  searchParams?: URLSearchParams;
  url?: string;
  label?: string;
};

const ExportButton: React.FunctionComponent<ExportButtonProps> = ({
  type,
  enabled,
  url,
  searchParams,
  label = 'Export',
}) => {
  const classes = useStyles();
  const { currentCompany } = useCompanyContext();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [csvStatus, setCsvStatus] = React.useState('');
  const [csvExportMutation, { loading: isCsvExportLoading }] = useDataHubCsvExportMutation();
  const [reconReportMutation, { loading: isReconReportLoading }] = useReconReportCsvExportMutation();
  const [missingDataReportMutation, { loading: isMissingDataReportLoading }] =
    useMissingDataExceptionReportCsvExportMutation();
  const [depositsReportMutation, { loading: isDepositReportLoading }] = useDepositsReportCsvExportMutation();
  const [cashTransactionReportMutation, { loading: isCashTransactionReportLoading }] =
    useCashTransactionReportCsvExportMutation();
  const [revenueTransactionReportMutation, { loading: isRevenueTransactionReportLoading }] =
    useRevenueTransactionReportCsvExportMutation();
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setCsvStatus('');
    setIsOpen(false);
  };

  const exportDataHubData = async () => {
    const { data } = await csvExportMutation({
      variables: {
        entityId: currentCompany.id,
      },
    });
    if (data?.dataHubCsvExport?.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const exportReconReportData = async () => {
    const variables: Record<string, string | null> = {};
    if (searchParams) {
      Array.from(searchParams.keys()).map((k: string) => (variables[k] = searchParams.get(k)));
    }
    const { data } = await reconReportMutation({ variables });
    if (data?.reconReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const exportMissingDataReportData = async () => {
    const { data } = await missingDataReportMutation();
    if (data?.missingDataExceptionReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const depositsReportData = async () => {
    const { data } = await depositsReportMutation({ variables: { ...searchParams, entityId: currentCompany.id } });
    if (data?.depositsReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const cashTransactionReportData = async () => {
    const variables = {} as CashTransactionReportCsvExportMutationVariables;
    if (searchParams) {
      Array.from(searchParams.keys()).map((k: string) => {
        const value = searchParams.get(k);
        return value && !isNaN(Number(value)) ? (variables[k] = Number(value)) : (variables[k] = value);
      });
    }
    const { data } = await cashTransactionReportMutation({ variables: { ...variables, entityId: currentCompany.id } });
    if (data?.cashTransactionReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const revenueTransactionReportData = async () => {
    const variables = {} as RevenueTransactionReportCsvExportMutationVariables;
    if (searchParams) {
      Array.from(searchParams.keys()).map((k: string) => {
        const value = searchParams.get(k);
        return value && !isNaN(Number(value)) ? (variables[k] = Number(value)) : (variables[k] = value);
      });
    }
    const { data } = await revenueTransactionReportMutation({
      variables: {
        ...variables,
        entityId: currentCompany.id,
      },
    });
    if (data?.revenueTransactionReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const handleClick = async () => {
    if (
      !enabled ||
      isCsvExportLoading ||
      isReconReportLoading ||
      isMissingDataReportLoading ||
      isDepositReportLoading ||
      isCashTransactionReportLoading ||
      isRevenueTransactionReportLoading
    ) {
      return;
    }
    try {
      switch (type) {
        case TCsvExportType.DATA_HUB:
          exportDataHubData();
          break;
        case TCsvExportType.RECON_REPORT:
          exportReconReportData();
          break;
        case TCsvExportType.MISSING_DATA_REPORT:
          exportMissingDataReportData();
          break;
        case TCsvExportType.DEPOSITS_REPORT:
          depositsReportData();
          break;
        case TCsvExportType.CASH_TRANSACTION_REPORT:
          cashTransactionReportData();
          break;
        case TCsvExportType.REVENUE_TRANSACTION_REPORT:
          revenueTransactionReportData();
          break;
      }
    } catch (err) {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
    openModal();
  };

  return (
    <>
      <a
        className={classes.exportCsv}
        href={url || '#'}
        target={url ? '_blank' : undefined}
        rel={url ? 'nonreferrer' : url}
        onClick={url ? undefined : handleClick}
      >
        <img src={`${getAssetPath()}/images/icon_csv.svg`} height="16" />
        {label}
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Invite User"
        style={{
          content: {
            width: '664px',
            height: '120px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
          },
        }}
      >
        <div className={classes.modalHeader}>
          <p>CSV Export</p>
          <button onClick={closeModal} className={classes.closeModalBtn}>
            {'\u00d7'}
          </button>
        </div>
        <div className={classes.modalSection}>{csvStatus}</div>
      </Modal>
    </>
  );
};

export default ExportButton;
