import _ from 'lodash';

import { MenuItem } from '../../common/MenuDropdown.tsx';
import { CompanyModel, HoldingCompanyModel } from '../../../generated/graphql.tsx';

export const getDemoCompanyEntities = (companyName: string, callback: (postFix: string) => void): MenuItem[] => [
  {
    label: `${companyName} US`,
    onClick: () => callback('US'),
    id: '1',
  },
  {
    label: `${companyName} EU`,
    onClick: () => callback('EU'),
    id: '2',
  },
  {
    label: `${companyName} APAC`,
    onClick: () => callback('APAC'),
    id: '3',
  },
];

export const getDemoCompanyLabel = (companyName: string) =>
  `${companyName} ${localStorage.getItem('multiEntity') || 'US'}`;

export const getEntities = (
  holdingCompany: HoldingCompanyModel | undefined,
  callback: (company: CompanyModel) => void
): MenuItem[] => {
  if (!holdingCompany?.companies) return [];
  const dropdownCompanies = holdingCompany.companies?.map((company) => ({
    label: company.name,
    onClick: () => callback(company),
    id: company.id,
  }));

  /**
   * We are removing the companies from the holding company object
   * to treat holding company as a company object
   */
  const holdingCompanyAsCompany = _.omit(holdingCompany, ['companies']) as CompanyModel;

  dropdownCompanies.unshift({
    label: holdingCompany.name,
    onClick: () => callback(holdingCompanyAsCompany),
    id: holdingCompany.id,
  });

  return dropdownCompanies || [];
};
