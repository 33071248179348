import { noop } from 'lodash';

import { LazyQueryHookOptions } from '@apollo/client';

import { TIntervalUnit } from '../../../../src/types/BaseTypes.ts';
import { getDefaultDashboardYear } from '../../../components/RevenueCardWithBanner.tsx';
import { useCompanyContext } from '../../../context/CompanyContext.tsx';
import {
  GetRevenueTableQuery,
  GetRevenueTableV2Query,
  GetRevenueTableV2QueryVariables,
  GetTransformedRevenueQueryVariables,
  useGetRevenueTableLazyQuery,
  useGetRevenueTableQuery,
  useGetRevenueTableV2LazyQuery,
  useGetRevenueTableV2Query,
  useGetTransformedRevenueLazyQuery,
} from '../../../generated/graphql.tsx';

export const useLazyQueryRevenueTable = (
  baseOptions?: Pick<
    LazyQueryHookOptions<GetRevenueTableV2Query, GetRevenueTableV2QueryVariables | GetTransformedRevenueQueryVariables>,
    'onCompleted' | 'fetchPolicy' | 'variables'
  >,
  isTransformedRevenue?: boolean,
  pivotId?: string
) => {
  const { isSupportRevenueTableV2, isRealCustomerCompany, currentCompany } = useCompanyContext();

  const fetchRevenueTable = isSupportRevenueTableV2 ? useGetRevenueTableV2LazyQuery : useGetRevenueTableLazyQuery;

  const useLazyRevenueTable = isTransformedRevenue ? useGetTransformedRevenueLazyQuery : fetchRevenueTable;

  const selectedYear = getDefaultDashboardYear(isRealCustomerCompany, currentCompany?.name);

  const revenueTable = useLazyRevenueTable({
    fetchPolicy: baseOptions?.fetchPolicy || 'cache-first',
    onCompleted: baseOptions?.onCompleted || noop,
    variables: {
      pivotId: pivotId || '',
      granularity: TIntervalUnit.MONTH,
      selectedYear: +(selectedYear || new Date().getFullYear()),
    },
  });

  return revenueTable;
};

export const useQueryRevenueTable = (
  baseOptions?: Pick<
    LazyQueryHookOptions<GetRevenueTableV2Query, GetRevenueTableV2QueryVariables>,
    'onCompleted' | 'fetchPolicy' | 'variables'
  >
) => {
  const { isSupportRevenueTableV2, isRealCustomerCompany, currentCompany } = useCompanyContext();

  const useRevenueTableQuery = isSupportRevenueTableV2 ? useGetRevenueTableV2Query : useGetRevenueTableQuery;

  const selectedYear = getDefaultDashboardYear(isRealCustomerCompany, currentCompany?.name);

  const { data } = useRevenueTableQuery({
    fetchPolicy: baseOptions?.fetchPolicy || 'cache-and-network',
    onCompleted: baseOptions?.onCompleted || noop,
    variables: {
      entityId: currentCompany?.id,
      granularity: TIntervalUnit.MONTH,
      selectedYear: +(selectedYear || new Date().getFullYear()),
      ...(baseOptions?.variables || {}),
    },
  });

  return (data as GetRevenueTableV2Query)?.revenueTableV2 || (data as GetRevenueTableQuery)?.revenueTable;
};
