export enum Section {
  LineItem,
  Transaction,
  Contract,
  Customer,
}

export type DataHubSingleItemCardProps = {
  initialOpenSection?: Section;
};
