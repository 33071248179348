import React from 'react';

import { useParams } from 'react-router-dom';

import Banner from '../../../components/Banner.tsx';
import { CreditDetails } from '../components/detailsCard/CreditDetails.tsx';
import { DataHubSingleItemCardProps } from '../types.ts';
import ErrorMessage from '../../../components/common/ErrorMessage.tsx';
import LoaderAnimation from '../../../components/common/LoaderAnimation.tsx';
import { PayoutDetails } from '../components/detailsCard/PayoutDetails.tsx';
import { RefundDetails } from '../components/detailsCard/RefundDetails.tsx';
import { useCompanyContext } from '../../../context/CompanyContext.tsx';
import { useSharedStyles } from '../../../utils/CssUtil.ts';
import {
  Credit,
  Payout,
  Refund,
  useGetSingleCreditItemQuery,
  useGetSinglePayoutItemQuery,
  useGetSingleRefundItemQuery,
} from '../../../generated/graphql.tsx';

const DataHubSingleItemCard: React.FunctionComponent<DataHubSingleItemCardProps> = () => {
  const sharedClasses = useSharedStyles();
  const { id, type } = useParams();
  const { currentCompany } = useCompanyContext();

  let gqlQuery;

  if (type === 'credit') {
    gqlQuery = useGetSingleCreditItemQuery;
  } else if (type === 'refund') {
    gqlQuery = useGetSingleRefundItemQuery;
  } else if (type === 'payout') {
    gqlQuery = useGetSinglePayoutItemQuery;
  }

  // Incase anything else we don't support
  if (!gqlQuery) {
    return <ErrorMessage error={`Incorrect route ${type} check component DataHubSingleItemCard.tsx`} />;
  }

  const {
    loading: isLoading,
    error,
    data,
  } = gqlQuery({
    variables: { id: id ?? '', entityId: currentCompany.id },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner showSearchBar />
      <div
        className={sharedClasses.contentWrapper}
        style={{
          marginTop: '-250px',
        }}
      >
        {isLoading || !data ? (
          <div className={sharedClasses.contentLoaderContainer}>
            <LoaderAnimation height={80} />
          </div>
        ) : (
          <>
            {data?.payoutDetails && <PayoutDetails payout={data.payoutDetails as Payout} />}
            {data?.refundDetails && <RefundDetails refund={data.refundDetails as Refund} />}
            {data?.creditDetails && <CreditDetails credit={data.creditDetails as Credit} />}
          </>
        )}
      </div>
    </div>
  );
};

export default DataHubSingleItemCard;
