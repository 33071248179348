import React from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import { gql, useQuery } from '@apollo/client';

import Banner from '../Banner';
import DataGrid from '../data_grid/DataGrid';
import { DataGridRow } from '../../../src/types/DataGridTypes';
import DateRangeFilter from '../common/DateRangeFilter';
import ErrorMessage from '../common/ErrorMessage.tsx';
import ExportButton from '../CSVExportButton';
import LoaderAnimation from '../common/LoaderAnimation';
import { TCsvExportType } from '../../../src/types/CsvExportTypes';
import { TCurrency } from '../../../src/types/BaseTypes';
import { TransactionCellRowFragment } from '../../apollo/GraphQLFragments';
import { useCompanyContext } from '../../context/CompanyContext.tsx';
import { useSharedStyles } from '../../utils/CssUtil';
import { GetWaterfallTableQuery, GetWaterfallTableQueryVariables } from '../../generated/graphql';

const useStyles = createUseStyles({
  revenueWaterfallTable: {
    '&.tabulator': {
      fontSize: '11px',
      overflow: 'visible',
      '& .tabulator-tableholder': {
        overflow: 'visible',
        overflowX: 'auto',
      },
      '& .tabulator-header .tabulator-headers .tabulator-col[role="columnheader"]': {
        textAlign: 'center',
      },
    },
  },
});

const QUERY = gql`
  ${TransactionCellRowFragment}
  query GetWaterfallTable($start_time_ms: Float!, $end_time_ms: Float!, $entityId: String!) {
    revenueWaterfallTable(start_time_ms: $start_time_ms, end_time_ms: $end_time_ms, entityId: $entityId) {
      rows {
        ...TransactionCellRowFragment
      }
    }

    currentUser {
      company {
        isRealCustomerCompany
      }
    }
  }
`;

// TODO: add a date selector to customize the date range
const endDate = moment(new Date()).endOf('m').toDate();
const startDate = moment(new Date('2023-06-01')).startOf('y').toDate();

// If testing locally with prod data, set the end and start date to the year where you have data.
// Also commenting this out for now - we just need to add it back in if we ever deploy to the demo env.
// if (!isOnProduction) {
//   endDate = moment(new Date('2022-06-01')).endOf('y').subtract(12, 'h').toDate();
//   startDate = moment(new Date('2022-06-01')).startOf('y').add(12, 'h').toDate();
// }

const ReportsCard: React.FunctionComponent = () => {
  const { currentCompany } = useCompanyContext();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const queryParams = {
    start_time_ms: startDate.getTime(),
    end_time_ms: endDate.getTime(),
    currency: TCurrency.USD,
    entityId: currentCompany.id,
  };

  const {
    loading: isLoading,
    error,
    data,
  } = useQuery<GetWaterfallTableQuery, GetWaterfallTableQueryVariables>(QUERY, {
    variables: queryParams,
  });

  const isRealCustomerCompany = !!data?.currentUser.company?.isRealCustomerCompany;

  const columns: TabulatorTypes.ColumnDefinition[] = [
    {
      title: 'Month',
      field: 'Month',
      headerSort: false,
      minWidth: 70,
    },
  ];

  const flattenedData: DataGridRow[] =
    data?.revenueWaterfallTable?.rows?.map((row, rowIdx) => {
      return row.cells.reduce(
        (acc, cur) => {
          if (rowIdx === 0) {
            columns.push({
              title: cur.label,
              field: cur.label,
              headerSort: false,
              minWidth: 100,
              widthGrow: cur.customClass ? 1.3 : 1,
              cssClass:
                `${cur.isLeftAligned ? '' : sharedClasses.contentCellRightAlign} ${cur.customClass}` || undefined,
            });
          }
          return {
            ...acc,
            [cur.label]: cur.content,
            _rowCssClass: row.customClass,
          };
        },
        { Month: row.label }
      );
    }) || [];

  const options: TabulatorTypes.Options = {
    data: flattenedData,
    rowFormatter: (row: TabulatorTypes.RowComponent) => {
      const data = row.getData();
      if (data._rowCssClass) {
        row.getElement().classList.add(data._rowCssClass);
      }
    },
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={sharedClasses.content}>
          <div className={sharedClasses.contentHeaderWrapper}>
            <div className={sharedClasses.contentHeader}>
              <div className={sharedClasses.cardTabs}>
                <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
                  <span>Deferred Revenue Waterfall</span>
                </div>
                {isLoading || isRealCustomerCompany ? undefined : (
                  <>
                    <div className={sharedClasses.cardTab}>
                      <a href="#">Revenue by Streams</a>
                    </div>
                    <div className={sharedClasses.cardTab}>
                      <a href="#">Revenue by Geo</a>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={sharedClasses.contentHeaderUtils}>
              {!isLoading && !isRealCustomerCompany && data ? (
                <>
                  <DateRangeFilter onChange={() => null} />
                  <ExportButton type={TCsvExportType.FAKE} />
                </>
              ) : null}
            </div>
          </div>
          {isLoading ? (
            <div className={sharedClasses.contentLoaderContainer}>
              <LoaderAnimation height={80} />
            </div>
          ) : (
            <div style={{ overflowX: 'scroll' }}>
              <DataGrid className={classes.revenueWaterfallTable} columns={columns} options={options} />
              <div className={sharedClasses.afterTable}>
                <div className={sharedClasses.afterTableLeft}></div>
                <div className={sharedClasses.afterTableRight}>Generated at {new Date().toString()}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportsCard;
