import React, { useRef, useState } from 'react';

import Modal from 'react-modal';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import Colors from './common/Colors';
import { DateFormatYearMonthDateTime } from '../../src/utils/FormatUtil';
import LoaderAnimation from './common/LoaderAnimation';
import { Spinner } from './Spinner.tsx';
import { TNotificationType } from '../../src/types/NotificationTypes';
import Toggle from './common/Toggle';
import { UserNavItemProps } from '../features/notification/types.ts';
import { cancelLink } from '../apollo/ApolloClient.ts';
import getAssetPath from '../utils/AssetPathUtil';
import { getBaseRoute } from '../utils/GetHomePageRoute.ts';
import { useAppContext } from '../context/AppContext.tsx';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import useInterval from '../hooks/useInterval';
import { useUserNavItemStyles } from '../features/notification/styles.ts';
import FormButton, { FormButtonStyle } from './common/FormButton';
import {
  Notification,
  useGetAllNotificationsQuery,
  useGetNewNotificationsCountQuery,
  useLogoutUserMutation,
  useSetNotificationToSeenMutation,
  useUpdateUserDisableEmailNotificationSettingsMutation,
} from '../generated/graphql';

const MAX_NEW_NOTIFICATIONS_COUNT = 5;
const NOTIFICATION_LIMIT_PER_PAGE = 25;
const NOTIFICATIONS_REFRESH_INTERVAL = 60000; // 1 minute

const UserNavItem: React.FunctionComponent<UserNavItemProps> = (props: UserNavItemProps) => {
  const navItemClasses = useUserNavItemStyles();
  const formatNewNotificationsCount = (count: number): string | undefined => {
    if (!count) {
      return undefined;
    }

    return count > 9 ? '9+' : String(count);
  };
  return (
    <div className={navItemClasses.userNavItem}>
      <button className={navItemClasses.button} onClick={props.onClick}>
        <img src={`${getAssetPath()}/images/icon_${props.iconType}.svg`} width={30} />
        {props.notifCount ? (
          <span className={navItemClasses.notifCount}>{formatNewNotificationsCount(props.notifCount)}</span>
        ) : null}
      </button>
      {props.dropdown ? (
        <div className={classnames(navItemClasses.userNavDropdown, props.listClassName)}>{props.dropdown}</div>
      ) : null}
    </div>
  );
};

const useStyles = createUseStyles({
  modalOverlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '61px',

    '& > p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0 16px',
    },
  },
  closeModalBtn: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    height: '100%',
    overflowY: 'auto',
    fontSize: '16px',
  },
  modalLine: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 8px 0 16px',
  },
  modalFooter: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    padding: '0 8px',
  },
  seeMore: {
    margin: 0,
    fontSize: '12px',
    cursor: 'pointer',
    textAlign: 'center',
    color: Colors.MEDIUM_GRAY,

    '&:hover': {
      color: Colors.DARK_GRAY,
    },
  },
  userNavContainer: {
    alignSelf: 'end',
    display: 'flex',
    flexDirection: 'row',
    marginRight: '10px',
  },
  notificationContainer: {
    width: 220,
  },
});

const useNotificationMessageStyles = createUseStyles({
  notification: {
    fontFamily: 'Arial',
    fontSize: '12px',
    padding: '16px',

    '&:hover': {
      backgroundColor: Colors.FAINTEST_BLUE,
    },
  },
  notificationContent: {
    color: Colors.DARK_GRAY,
    margin: '2px 0',
  },

  notificationDate: {
    color: Colors.MEDIUM_GRAY,
    margin: 0,
    marginTop: 5,
    fontSize: '10px',
    textAlign: 'right',
  },
  notSeen: {
    fontWeight: 'bold',
  },
  link: {
    cursor: 'pointer',
  },
});

interface NotificationMessageProps {
  content: string;
  url?: string;
  date: string;
  isSeen: boolean;
  type: TNotificationType;
  onError: (err: string) => void;
}

const NotificationMessage: React.FunctionComponent<NotificationMessageProps> = ({
  isSeen,
  date,
  content,
  url,
  type,
  onError,
}) => {
  const navItemClasses = useUserNavItemStyles();
  const notificationMessageClasses = useNotificationMessageStyles();
  const onClick = () => {
    if (type === TNotificationType.CSV_DOWNLOAD && url) {
      downloadFile(url);
    } else if (url) {
      openInNewTab(url);
    }
  };
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const downloadFile = (url: string) => {
    fetch(url)
      .then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const fileName = response.headers.get('fileName');
          a.download = `${fileName}-${moment().format(DateFormatYearMonthDateTime)}.csv`;
          a.click();
        });
      })
      .catch(() => {
        onError('Something went wrong when downloading a CSV. Please try again later');
      });
  };

  return (
    <div
      onClick={onClick}
      className={classnames(navItemClasses.userNavDropdownItemDiv, notificationMessageClasses.notification, {
        [notificationMessageClasses.notSeen]: !isSeen,
        [notificationMessageClasses.link]: !!url,
      })}
    >
      <p className={notificationMessageClasses.notificationContent}>{content}</p>
      <p className={notificationMessageClasses.notificationDate}>{date}</p>
    </div>
  );
};

interface SeeMoreProps {
  onClick: () => void;
  visible: boolean;
  label: string;
}
const SeeMore: React.FunctionComponent<SeeMoreProps> = ({ onClick, visible, label }) => {
  const navItemClasses = useUserNavItemStyles();
  const classes = useStyles();
  if (!visible) {
    return null;
  }

  return (
    <div className={classnames(navItemClasses.userNavDropdownItemDiv, classes.seeMore)} onClick={onClick}>
      {label}
    </div>
  );
};

enum DropdownType {
  Notifications,
  Profile,
}

const UserNav: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { currentCompany } = useCompanyContext();
  const { onResetAuth, isAuthenticated, currentUser } = useAppContext();
  const [isLogout, setIsLogout] = useState(false);
  const client = useApolloClient();
  const navItemClasses = useUserNavItemStyles();
  const classes = useStyles();

  const {
    data: newNotifications,
    loading: isNewNotificationsLoading,
    refetch: refetchNewNotifications,
  } = useGetNewNotificationsCountQuery({
    skip: !isAuthenticated,
  });

  const {
    data: allNotificationsData,
    loading: isAllNotificationsLoading,
    refetch: getAllNotifications,
  } = useGetAllNotificationsQuery({
    variables: {
      limit: NOTIFICATION_LIMIT_PER_PAGE,
    },
  });

  const [setNotificationToSeen] = useSetNotificationToSeenMutation();

  const [logoutUser] = useLogoutUserMutation();

  const [changeStateMutation] = useUpdateUserDisableEmailNotificationSettingsMutation();

  const [dropdownTypeState, updateDropdownTypeState] = useState<DropdownType | null>(null);
  const [allNotifications, setAllNotifications] = useState<Notification[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const ref = useRef(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [notificationSettingsModalIsOpen, setNotificationSettingsModalIsOpen] = React.useState(false);

  // periodically fetch for notifications updates
  useInterval(async () => {
    await refetchNewNotifications();
  }, NOTIFICATIONS_REFRESH_INTERVAL);

  function setDropdownNotificationToSeen() {
    allNotificationsData?.getAllNotifications
      ?.slice(0, MAX_NEW_NOTIFICATIONS_COUNT)
      .filter((n) => !n.entity.isSeen)
      .forEach(async (n) => await setNotificationToSeen({ variables: { notification_id: n.id } }));
  }

  function openModal() {
    refetchNewNotifications();
    setIsOpen(true);
    // update state of notifications that had not seen in dropdown of notifications
    setDropdownNotificationToSeen();
  }

  function closeModal() {
    if (errorMessage) {
      setErrorMessage('');
    }
    setIsOpen(false);
    // update state of all seen notifications
    allNotifications
      .filter((n) => !n.entity.isSeen)
      .forEach(async (n) => await setNotificationToSeen({ variables: { notification_id: n.id } }));

    refetchNewNotifications();
  }

  function openNotificationSettingsModal() {
    setNotificationSettingsModalIsOpen(true);
  }

  function closeNotificationSettingsModal() {
    setNotificationSettingsModalIsOpen(false);
  }

  const toggleHandler = (state: boolean) => () => {
    changeStateMutation({
      variables: {
        disableEmailNofitication: state === true ? false : true,
      },
    });
  };

  React.useEffect(() => {
    if (allNotificationsData?.getAllNotifications !== undefined && allNotifications) {
      const updatedNotifications: Array<Notification> = [];
      for (const n of allNotificationsData?.getAllNotifications) {
        updatedNotifications.push(n as Notification);
      }
      setAllNotifications(updatedNotifications);
    }
  }, [allNotificationsData?.getAllNotifications]);

  const handleNotificationError = (errMsg: string) => {
    setErrorMessage(errMsg);
    openModal();
  };

  const getOldestTimestamp = (): number =>
    new Date(allNotifications[allNotifications.length - 1].entity.createdAt).getTime();

  const getNotificationsDropDownContent = () => {
    const latestNotifications = allNotifications.slice(0, MAX_NEW_NOTIFICATIONS_COUNT);
    const newNotificationsCount = newNotifications?.getNotSeenNotificationsCount?.count || 0;
    const notificationSettingsBtn = (
      <SeeMore
        key="notification-settings"
        visible
        onClick={() => {
          openNotificationSettingsModal();
          closeModal();
          updateDropdownTypeState(null);
        }}
        label="Notification settings"
      />
    );
    const seeAllBtn = (
      <SeeMore
        key="see-all"
        visible
        onClick={() => {
          openModal();
          closeNotificationSettingsModal();
          updateDropdownTypeState(null);
        }}
        label={
          newNotificationsCount > MAX_NEW_NOTIFICATIONS_COUNT
            ? `See ${newNotificationsCount - MAX_NEW_NOTIFICATIONS_COUNT} more new notification.`
            : 'See all notifications'
        }
      />
    );
    const latestNotificationsContent = (latestNotifications || [])?.map((n: Notification) => (
      <NotificationMessage
        key={n.id}
        isSeen={n?.entity.isSeen}
        content={n.entity.content.text}
        url={n?.entity?.content?.url || ''}
        date={moment(n.entity.createdAt).fromNow()}
        type={n?.entity?.type as TNotificationType}
        onError={handleNotificationError}
      />
    ));
    return [...latestNotificationsContent, notificationSettingsBtn, seeAllBtn];
  };

  const onLogout = async () => {
    cancelLink.cancelRequests();
    await logoutUser();
    onResetAuth();
    setIsLogout(true);
    navigate('/login', { replace: true });
    await client.clearStore();
  };

  return (
    <div ref={ref} className={classes.userNavContainer}>
      <UserNavItem
        listClassName={classes.notificationContainer}
        iconType="notifications"
        dropdown={dropdownTypeState === DropdownType.Notifications && getNotificationsDropDownContent()}
        notifCount={isNewNotificationsLoading ? undefined : newNotifications?.getNotSeenNotificationsCount?.count}
        onClick={() => {
          getAllNotifications({ beforeTimeMs: Date.now(), limit: NOTIFICATION_LIMIT_PER_PAGE });
          updateDropdownTypeState(DropdownType.Notifications);
        }}
      />
      <UserNavItem
        iconType="profile"
        dropdown={
          dropdownTypeState === DropdownType.Profile ? (
            <>
              <Link
                className={navItemClasses.userNavDropdownItemA}
                role="link"
                to={getBaseRoute(currentCompany.name, 'security_settings')}
              >
                Security settings
              </Link>
              <div className={navItemClasses.userNavDropdownItemA} onClick={onLogout}>
                Sign out
                {isLogout && <Spinner size={16} />}
              </div>
            </>
          ) : null
        }
        onClick={() => updateDropdownTypeState(DropdownType.Profile)}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Invite User"
        overlayClassName={classes.modalOverlay}
        style={{
          content: {
            width: '664px',
            minHeight: '224px',
            maxHeight: '50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            fontFamily: `MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif`, // because modal is portal
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <div className={classes.modalHeader}>
          <p>Notifications ({allNotifications.length})</p>
          <button onClick={closeModal} className={classes.closeModalBtn}>
            {'\u00d7'}
          </button>
        </div>
        <div className={classes.modalSection}>
          {!isAllNotificationsLoading && !errorMessage ? (
            allNotifications
              ?.map((n: Notification) => {
                return (
                  <NotificationMessage
                    key={n.id}
                    isSeen={n?.entity.isSeen}
                    content={n?.entity.content.text}
                    url={n?.entity?.content?.url || ''}
                    date={moment(n.entity.createdAt).fromNow()}
                    type={n?.entity?.type as TNotificationType}
                    onError={handleNotificationError}
                  />
                );
              })
              .concat(
                <SeeMore
                  key="see-more"
                  onClick={() => {
                    getAllNotifications({
                      beforeTimeMs:
                        allNotificationsData?.getAllNotifications === undefined
                          ? new Date().getTime()
                          : getOldestTimestamp(),
                      limit: NOTIFICATION_LIMIT_PER_PAGE,
                    });
                  }}
                  label={'See more notifications'}
                  visible={
                    !(
                      allNotificationsData?.getAllNotifications &&
                      allNotificationsData?.getAllNotifications?.length < NOTIFICATION_LIMIT_PER_PAGE
                    )
                  }
                />
              )
          ) : errorMessage ? (
            <p>{errorMessage}</p>
          ) : (
            <LoaderAnimation height={80} />
          )}
        </div>
      </Modal>
      <Modal
        isOpen={notificationSettingsModalIsOpen}
        onRequestClose={closeNotificationSettingsModal}
        contentLabel="Notification settings"
        overlayClassName={classes.modalOverlay}
        style={{
          content: {
            width: '400px',
            minHeight: '200px',
            maxHeight: '15%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            fontFamily: `MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif`, // because modal is portal
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <div className={classes.modalHeader}>
          <p>Notifications settings</p>
          <button onClick={closeNotificationSettingsModal} className={classes.closeModalBtn}>
            {'\u00d7'}
          </button>
        </div>
        <div className={classes.modalSection}>
          <div className={classes.modalLine}>
            Receive new notifications via email:
            <Toggle
              isOn={currentUser?.settings?.disableEmailNofitication || false}
              onClick={toggleHandler(currentUser?.settings?.disableEmailNofitication || false)}
            />
          </div>
        </div>
        <div className={classes.modalFooter}>
          <FormButton style={FormButtonStyle.PLAIN} width={60} height={24} onClick={closeNotificationSettingsModal}>
            Close
          </FormButton>
        </div>
      </Modal>
    </div>
  );
};

export default UserNav;
