import React, { useMemo, useState } from 'react';

import { ColumnDefinition } from 'react-tabulator';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import _ from 'lodash';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Colors from './common/Colors';
import DataGrid from './data_grid/DataGrid';
import { DataGridRow } from './data_grid/DataGridTypes.ts';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import PaymentRecognitionScheduleCardCashTransaction from './payment_recognition/PaymentRecognitionScheduleCardCashTransaction.tsx';
import PaymentRecognitionScheduleCardContractTable from './payment_recognition/PaymentRecognitionScheduleCardContractTable';
import PaymentRecognitionScheduleCardInvoice from './payment_recognition/PaymentRecognitionScheduleCardInvoice';
import PaymentRecognitionScheduleCardInvoiceOverview from './payment_recognition/PaymentRecognitionScheduleCardInvoiceOverview';
import PaymentRecognitionScheduleCardPayout from './payment_recognition/PaymentRecognitionScheduleCardPayout';
import PaymentRecognitionScheduleCardRefund from './payment_recognition/PaymentRecognitionScheduleCardRefund.tsx';
import PaymentRecognitionScheduleCardVendorCost from './payment_recognition/PaymentRecognitionScheduleCardVendorCost.tsx';
import PaymentRecognitionScheduleCredit from './payment_recognition/PaymentRecognitionScheduleCredit.tsx';
import SectionNavHeader from './SectionNavHeader';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { useAppContext } from '../context/AppContext.tsx';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import {
  GenericKeyValuePair,
  GetRecognitionScheduleQuery,
  JournalEntriesForInvoice,
  Payout,
  Refund,
  TransactionRecognitionTableContractLevelFragmentFragment,
  TransactionRecognitionTableInvoiceFragmentFragment,
  VendorCost,
} from '../generated/graphql';
import PaymentRecognitionScheduleCardAccounting, {
  PaymentRecognitionScheduleModal,
} from './payment_recognition/PaymentRecognitionScheduleCardAccounting';
import { TPaymentRecognitionScheduleSections, TReportPage } from '../../src/types/BaseTypes';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';

const OUTSTANDING = 'Outstanding';

const useStyles = createUseStyles({
  paymentRecognitionTable: {
    '&.tabulator': {
      fontSize: '12px',
      overflow: 'visible',
      '& .tabulator-header .tabulator-headers .tabulator-col[role="columnheader"]': {
        textAlign: 'center',
      },
      '& .tabulator-tableholder': {
        overflow: 'visible',
        overflowX: 'auto',
        '& .tabulator-table': {
          '& .tabulator-row': {
            '& .content-cell-disabled': {
              color: Colors.MEDIUM_GRAY,
            },
            '&.content-row-highlight': {
              '& .tabulator-cell': {
                textDecoration: 'none',
                '& a': {
                  color: Colors.WHITE,
                  textDecoration: 'none',
                },
              },
              '& .content-cell-disabled': {
                color: Colors.WHITE,
                backgroundColor: Colors.MAIN_BLUE_HIGHLIGHT,
              },
            },
          },
        },
      },
    },
  },
  accountSelector: {
    paddingBottom: 10,
  },
});

type SelectedRecType = {
  value: string;
  label: string;
  selected?: boolean;
};

type PaymentRecognitionScheduleCardProps = {
  isLoading?: boolean;
  isRealCustomerCompany?: boolean;
  data?: GetRecognitionScheduleQuery;
  contractData?: TransactionRecognitionTableContractLevelFragmentFragment;
  invoice?: TransactionRecognitionTableInvoiceFragmentFragment;
  payout?: Payout;
  refund?: Refund;
  vendorCost?: VendorCost;
  availableRecognitionAccounts: GenericKeyValuePair[];
  transactionId: string;
};
TPaymentRecognitionScheduleSections;
const PaymentRecognitionScheduleCard: React.FunctionComponent<PaymentRecognitionScheduleCardProps> = ({
  data,
  contractData,
  isLoading,
  isRealCustomerCompany,
  invoice,
  payout,
  refund,
  vendorCost,
  availableRecognitionAccounts,
  transactionId,
}: PaymentRecognitionScheduleCardProps) => {
  const { currentCompany } = useCompanyContext();
  const { currentUser } = useAppContext();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalInvoiceItemId, setModalInvoiceItemId] = React.useState<string | undefined>();
  const version = params.get('v');
  const selectedAccount = params.get('at');
  const [visibleColumn, setVisibleColumn] = useState<string | null>(null);

  const selectAccountOptions: SelectedRecType[] = useMemo(
    () =>
      availableRecognitionAccounts?.map(({ key, value }) => {
        const account = _.first(selectedAccount?.split(','));
        return {
          value,
          label: key,
          selected: account?.toLowerCase().includes(value.toLowerCase()),
        };
      }) || [],
    [availableRecognitionAccounts, selectedAccount]
  );

  const selectedAccountOption = useMemo(
    () =>
      selectAccountOptions.find((option) => {
        const regexpr = new RegExp(`\\b${selectedAccount}\\b`, 'g');
        return option.value.match(regexpr);
      }),
    [selectAccountOptions, selectedAccount]
  );

  if (selectedAccountOption) {
    selectedAccountOption.selected = true;
  }

  function updateSearchParam(key: string, value: string) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }

  const reportSettings = useMemo(
    () => currentCompany.settings?.reportSettings?.find((r) => r.name === TReportPage.INVOICE_ITEM_DRILLDOWN),
    [currentCompany.settings]
  );

  const isCashReconciliationEnabled = currentCompany.settings?.isCashReconciliationEnabled || false;

  const hasAccountingSection = (reportSettings?.elementSettings?.include || []).includes(
    TPaymentRecognitionScheduleSections.ACCOUNTING
  );

  const shouldSkipEventsSection = (reportSettings?.elementSettings?.exclude || []).includes(
    TPaymentRecognitionScheduleSections.EVENTS
  );

  // TODO: it's a workaround to display the data only for yearly contracts
  const IS_YEARLY_CONTRACT = useMemo(
    () =>
      !data
        ? false
        : data.recognitionScheduleV2?.sectionBreadcrumbs?.breadcrumbs.some((i) =>
            i.label.toLowerCase().endsWith('Yearly plans, Jan 2022'.toLowerCase())
          ),
    [data]
  );
  const paymentRecognitionColumns = useMemo(() => {
    const defaultCols = ['source system id', 'line item id'];

    if (!data?.recognitionScheduleV2?.rows?.[0]) return [];
    const hasDefaultCol = data?.recognitionScheduleV2?.rows?.[0].cells.find(({ label }) =>
      defaultCols.includes(label.toLowerCase())
    );

    const rows = [...getColumns(data.recognitionScheduleV2.rows[0], false, sharedClasses)];

    return !hasDefaultCol
      ? [
          {
            title: version === '2' ? 'Source System ID' : 'Line Item ID',
            field: 'id',
            headerSort: false,
            cssClass: classnames(sharedClasses.linkUnderlined),
            formatter: 'link',
            formatterParams: {
              labelField: 'id',
              urlField: 'idUrl',
            },
            minWidth: 120,
            frozen: true,
          } as TabulatorTypes.ColumnDefinition,
          ...rows,
        ]
      : rows;
  }, [data, version, sharedClasses]);

  paymentRecognitionColumns?.forEach((col) => {
    col.headerSort = false;
    col.resizable = col.title !== 'Source';
    col.minWidth = 80;
    col.maxWidth = col.frozen ? 250 : 80;
    col.widthGrow = col.frozen ? 1.3 : undefined;
    if (col.title.toLowerCase().startsWith('rec. in')) {
      col.cellClick = (_e, cell) => {
        const { idUrl } = cell.getData() as { idUrl?: string };
        if (idUrl) {
          const invoiceItemId = idUrl.match(/ii-[\w|\d]+/g)?.[0];
          if (invoiceItemId) {
            setIsModalOpen(true);
            setModalInvoiceItemId(invoiceItemId);
          }
        }
      };
    }
  });

  const flattenedData = useMemo(
    () => (data?.recognitionScheduleV2?.rows ? flattenData(data?.recognitionScheduleV2.rows) : []),
    [data?.recognitionScheduleV2?.rows, visibleColumn]
  );

  const paymentRecognitionOptions: TabulatorTypes.Options = {
    layout: 'fitData',
    sortMode: 'remote',
    columnDefaults: {
      title: '',
      headerSortTristate: true,
    },
    persistence: undefined,
    rowFormatter: (row: TabulatorTypes.RowComponent) => {
      const data = row.getData();
      if (data?._rowCssClass) {
        row?.getElement()?.classList?.add(data._rowCssClass);
      }
    },
  };

  const [defaultCol, selectiveCol] = useMemo(() => {
    const defaultCol: ColumnDefinition[] = [];
    let selectiveCol: Record<string, ColumnDefinition> = {};
    paymentRecognitionColumns.forEach((col) => {
      if (col.field?.startsWith(OUTSTANDING)) {
        selectiveCol = { ...selectiveCol, [col.field]: col };
      } else {
        defaultCol.push(col);
      }
    });
    return [defaultCol, selectiveCol];
  }, [paymentRecognitionColumns]);

  const updatedColumns = useMemo(() => {
    if (!Object.keys(selectiveCol).length) {
      return [...defaultCol];
    }
    let extraCol: ColumnDefinition = {} as ColumnDefinition;
    if (!visibleColumn || !Object.keys(selectiveCol).includes(visibleColumn)) {
      extraCol = _.first(Object.values(selectiveCol)) || ({} as ColumnDefinition);
    } else {
      extraCol = selectiveCol[visibleColumn];
    }
    return [...defaultCol, extraCol];
  }, [defaultCol, visibleColumn, selectiveCol]);

  // console.log('visible Column', selectedAccountOption);
  return (
    <div className={sharedClasses.contentWrapper}>
      {isLoading ? (
        <div className={sharedClasses.contentLoaderContainer}>
          <LoaderAnimation height={80} />
        </div>
      ) : (
        <>
          <div className={sharedClasses.contentHeaderWrapper}>
            <SectionNavHeader
              isLastItemClickable={data?.recognitionScheduleV2?.sectionBreadcrumbs?.shouldLinkLastBreadcrumb}
              sections={data?.recognitionScheduleV2?.sectionBreadcrumbs?.breadcrumbs || []}
            />
            <div className={sharedClasses.contentHeaderUtils}>
              {(!isRealCustomerCompany || version === '2') && (
                <ExportButton
                  type={TCsvExportType.RECOGNITION_SCHEDULE}
                  url={`/download/csv-recognition-schedule?${params.toString()}&ei=${currentCompany.id}`}
                />
              )}
            </div>
          </div>
          {!!availableRecognitionAccounts?.length && version === '2' && (
            <div className={classnames(sharedClasses.contentHeaderUtils, classes.accountSelector)}>
              <span>View recognized by: </span>
              <select onChange={(v) => updateSearchParam('at', v.target.value)}>
                {selectAccountOptions.map(({ label, value, selected }) => (
                  <option key={value} value={value} selected={selected}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          )}
          {Object.keys(selectiveCol).length ? (
            <div className={sharedClasses.contentHeaderUtils}>
              <span>Select Columns to Display:</span>
              <select onChange={(v) => setVisibleColumn(v.target.value)}>
                {Object.entries(selectiveCol).map(([field, { title }], index) => (
                  <option key={field} value={field} selected={visibleColumn ? visibleColumn === field : index === 0}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div></div>
          )}
          <div className={sharedClasses.content}>
            <div style={{ overflowX: 'scroll', overflowY: 'visible' }}>
              <DataGrid
                className={classes.paymentRecognitionTable}
                columns={updatedColumns}
                data={flattenedData as DataGridRow[]}
                options={paymentRecognitionOptions}
                shouldEnablePager={false}
                shouldEnableUrlFiltering
              />
            </div>
            <PaymentRecognitionScheduleModal
              key={modalInvoiceItemId}
              isModalOpen={isModalOpen}
              journalEntriesForInvoice={(data?.getJournalEntriesForInvoice || []) as JournalEntriesForInvoice[]}
              modalInvoiceItemId={modalInvoiceItemId ?? ''}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
          {transactionId.startsWith('ctx') ? (
            <PaymentRecognitionScheduleCardCashTransaction
              isRealCustomerCompany={isRealCustomerCompany}
              invoice={invoice}
              reportSettingsExclude={reportSettings?.elementSettings?.exclude}
              shouldSkipEventsSection={shouldSkipEventsSection}
              isCashReconciliationEnabled={isCashReconciliationEnabled}
              timezoneLocation={currentUser?.company?.timezoneLocation}
              companyName={currentUser?.company?.name}
              transactionId={transactionId}
            />
          ) : (
            <div></div>
          )}
          {transactionId.startsWith('rf-') ? (
            <PaymentRecognitionScheduleCardRefund
              isRealCustomerCompany={isRealCustomerCompany}
              invoice={invoice}
              refund={refund}
              reportSettingsExclude={reportSettings?.elementSettings?.exclude}
              shouldSkipEventsSection={shouldSkipEventsSection}
              isCashReconciliationEnabled={isCashReconciliationEnabled}
              timezoneLocation={currentUser?.company?.timezoneLocation}
              companyName={currentUser?.company?.name}
              transactionId={transactionId}
            />
          ) : (
            <div></div>
          )}
          {transactionId.startsWith('vc-') ? (
            <PaymentRecognitionScheduleCardVendorCost
              isRealCustomerCompany={isRealCustomerCompany}
              invoice={invoice}
              vendorCost={vendorCost}
              reportSettingsExclude={reportSettings?.elementSettings?.exclude}
              shouldSkipEventsSection={shouldSkipEventsSection}
              isCashReconciliationEnabled={isCashReconciliationEnabled}
              timezoneLocation={currentUser?.company?.timezoneLocation}
              companyName={currentUser?.company?.name}
              transactionId={transactionId}
            />
          ) : (
            <div></div>
          )}
          {/* {transactionId.startsWith('ii-') ? (
            <PaymentRecognitionScheduleCardTax
              isRealCustomerCompany={isRealCustomerCompany}
              invoice={invoice}
              reportSettingsExclude={reportSettings?.elementSettings?.exclude}
              shouldSkipEventsSection={shouldSkipEventsSection}
              isCashReconciliationEnabled={isCashReconciliationEnabled}
              timezoneLocation={currentUser?.company?.timezoneLocation}
              companyName={currentUser?.company?.name}
              transactionId={transactionId}
            />
          ) : (
            <div></div>
          )} */}
          {payout?.vendor ? (
            <PaymentRecognitionScheduleCardPayout
              isRealCustomerCompany={isRealCustomerCompany}
              payout={{ transactionId, ...payout }}
              reportSettingsExclude={reportSettings?.elementSettings?.exclude}
              shouldSkipEventsSection={shouldSkipEventsSection}
              isCashReconciliationEnabled={isCashReconciliationEnabled}
              timezoneLocation={currentUser?.company?.timezoneLocation}
              companyName={currentUser?.company?.name}
            />
          ) : (
            <div></div>
          )}
          <PaymentRecognitionScheduleCardContractTable
            isRealCustomerCompany={isRealCustomerCompany}
            contractData={contractData}
          />
          <PaymentRecognitionScheduleCardInvoiceOverview
            contractData={contractData}
            invoice={invoice}
            reportSettingsExclude={reportSettings?.elementSettings?.exclude}
            timezoneLocation={currentUser?.company?.timezoneLocation}
          />
          <PaymentRecognitionScheduleCardInvoice
            isRealCustomerCompany={isRealCustomerCompany}
            invoice={invoice}
            reportSettingsExclude={reportSettings?.elementSettings?.exclude}
            shouldSkipEventsSection={shouldSkipEventsSection}
            isCashReconciliationEnabled={isCashReconciliationEnabled}
            timezoneLocation={currentUser?.company?.timezoneLocation}
            companyName={currentUser?.company?.name}
          />
          <PaymentRecognitionScheduleCardAccounting
            isRealCustomerCompany={isRealCustomerCompany}
            invoice={invoice}
            getJournalEntriesForInvoice={(data?.getJournalEntriesForInvoice || []) as JournalEntriesForInvoice[]}
            hasAccountingSection={hasAccountingSection}
            IS_YEARLY_CONTRACT={IS_YEARLY_CONTRACT}
            timezoneLocation={currentUser?.company?.timezoneLocation}
          />
          {data?.creditDetails && <PaymentRecognitionScheduleCredit credit={data?.creditDetails} />}
        </>
      )}
    </div>
  );
};

export default PaymentRecognitionScheduleCard;
