import React from 'react';

import { Link } from 'react-router-dom';

import { SideBarHeaderProps } from './types';
import { assetPathGenerator } from '../../../../utils/AssetPathUtil';
import { useStyles } from './styles';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from '../../../../components/common/MenuDropdown';

export const Header = ({ showEntities, name, company, items, defaultDDValue }: SideBarHeaderProps) => {
  const classes = useStyles();
  const brandLogo = assetPathGenerator('/images/logo_small.png');

  return (
    <>
      <div className={classes.logoContainer}>
        <Link to="/app/home">
          <img className={classes.logoImage} src={brandLogo} />
        </Link>
      </div>
      <div className={classes.idContainer}>
        <div className={classes.nameContainer}>{name}</div>
        <div className={classes.companyContainer}>
          {/* ideal for the case when only one entity  */}
          {!showEntities ? (
            <div style={{ textAlign: 'center' }}>{company}</div>
          ) : (
            <MenuDropdown horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT} items={items}>
              {defaultDDValue}
            </MenuDropdown>
          )}
        </div>
      </div>
    </>
  );
};
