import React from 'react';

import { NavigationSidebar } from '../../components/NavigationSidebar';
import { PivotSetting } from '../../features/pivotSettings';
import { SidebarWidth } from '../../features/sideBar/constants/sideBarWidth';
import { useCompanyContext } from '../../context/CompanyContext.tsx';
import { useRevenueTableContext } from '../../context/RevenueTableContext.tsx';
import { useSideBarContext } from '../../features/sideBar/providers/SideBarProvider';
import { useStyles } from './styles.ts';

export const SideBar = () => {
  const { showSideBarType } = useSideBarContext();
  const { isSupportCustomPivot } = useRevenueTableContext();
  const width = SidebarWidth[showSideBarType];
  const classes = useStyles({ width });

  const { isRealCustomerCompany } = useCompanyContext();
  const showPivotSetting = !isRealCustomerCompany || isSupportCustomPivot;

  return (
    <div className={classes.container}>
      <NavigationSidebar />
      {showPivotSetting && <PivotSetting />}
    </div>
  );
};
