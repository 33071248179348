import React, { ReactElement } from 'react';

import { createUseStyles } from 'react-jss';

import Colors from '../common/Colors.tsx';
import { Credit } from '../../generated/graphql.tsx';
import SectionNavHeader from '../SectionNavHeader.tsx';
import SectionWithHeader from '../common/SectionWithHeader.tsx';
import { useSharedStyles } from '../../utils/CssUtil.ts';
import KeyValueList, { KeyValue } from '../common/KeyValueList.tsx';

const useStyles = createUseStyles({
  overviewSection: {
    '& *': {
      fontSize: '13px',
    },
    '& a, & a:hover, & a:visited': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dashed',
    },
  },
  triggeredRule: {
    backgroundColor: Colors.LIGHT_GRAY,
    boxSizing: 'border-box',
    padding: '15px',
    borderRadius: '3px',
    lineHeight: 1.3,
    marginBottom: '10px',
    width: '100%',

    '&:last-child': {
      marginBottom: 0,
    },

    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
      textDecoration: 'underline dashed',
    },
  },
});

type PaymentRecognitionScheduleCreditProps = {
  credit?: Credit;
};
const PaymentRecognitionScheduleCredit: React.FunctionComponent<PaymentRecognitionScheduleCreditProps> = ({
  credit,
}: PaymentRecognitionScheduleCreditProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  if (!credit) {
    return null;
  }
  const keyValuePairs: KeyValue[] = [
    {
      key: 'Numeral Id',
      value: credit.id,
    },
    {
      key: 'Description',
      value: credit.entity?.description || 'N/A',
    },
    {
      key: 'Vendor',
      value: credit.entity?.vendor || 'N/A',
    },
    {
      key: 'Discount Amount',
      value: credit.formattedBaseCurrencyAmount ?? 0,
    },
    {
      key: 'Source',
      value: credit.source ?? 'N/A',
    },
    {
      key: 'Order',
      value: credit.orderType ?? 'N/A',
    },
  ];

  let creditContainer: ReactElement | undefined = undefined;

  creditContainer = (
    <div style={{ marginTop: '10px ' }}>
      <div className={sharedClasses.contentHeaderWrapper}>
        <SectionNavHeader
          sections={[
            {
              label: 'Credit',
            },
          ]}
        />
        <div className={sharedClasses.contentHeaderUtils} />
      </div>

      <div className={classes.overviewSection} key={`credit-${credit.id}`}>
        <SectionWithHeader header={`Credit: ${credit.id}`}>
          <KeyValueList keyValuePairs={keyValuePairs} />
        </SectionWithHeader>
      </div>
    </div>
  );

  return <>{creditContainer}</>;
};

export default PaymentRecognitionScheduleCredit;
