import React, { ReactElement } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/client';

import Banner from './Banner';
import Colors from './common/Colors';
import Toggle from './common/Toggle';
import { useCompanyContext } from '../context/CompanyContext';
import { useSharedStyles } from '../utils/CssUtil';
import { UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables } from '../generated/graphql';

const COMPANY_SETTINGS_MUTATION = gql`
  mutation UpdateCompanySettings($company_id: String!, $force_two_factor: Boolean!) {
    updateSettings(companyId: $company_id, forceTwoFactor: $force_two_factor) {
      id
      settings {
        isTwoFactorRequired
      }
    }
  }
`;

const useStyles = createUseStyles({
  highlightContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    padding: '15px 50px 25px',
  },
  highlightItem: {
    marginRight: '30px',
    minWidth: '220px',
    textAlign: 'center',
  },
  highlightItemValue: {
    fontSize: '28px',
    fontWeight: 500,
  },
  highlightItemSubheader: {
    fontSize: '14px',
    marginTop: '5px',
  },
  settingsSubheader: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1px',
    paddingBottom: '10px',
  },
});

const CompanySettings: React.FunctionComponent = () => {
  const { currentCompany } = useCompanyContext();
  const [changeStateMutation] = useMutation<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables>(
    COMPANY_SETTINGS_MUTATION
  );
  const classes = useStyles();

  const sharedClasses = useSharedStyles();
  const toggleHandler = (id: string, state: boolean) => () => {
    changeStateMutation({
      variables: {
        company_id: id,
        force_two_factor: state === true ? false : true,
      },
    });
  };

  let toggle: null | ReactElement;
  if (!currentCompany) {
    toggle = null;
  } else {
    toggle = (
      <Toggle
        isOn={currentCompany.settings?.isTwoFactorRequired || false}
        onClick={toggleHandler(currentCompany.id, currentCompany.settings?.isTwoFactorRequired || false)}
      />
    );
  }
  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={classes.settingsSubheader}>Enable Two Factor Authentication</div>
        {toggle}
      </div>
    </div>
  );
};

export default CompanySettings;
