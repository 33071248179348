import React from 'react';

import Modal from 'react-modal';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { MutationFunctionOptions } from '@apollo/client/react/types/types';

import Colors from './common/Colors';
// import Colors from './common/Colors';
import FormButton from './common/FormButton';
import { TUserRole } from '../../src/types/BaseTypes';
import { useSharedStyles } from '../utils/CssUtil';
import { useToaster } from './common/Toaster/useToasterHook.tsx';
import { Exact, GetTeamUserListQuery, InviteUserMutation, SetRoleMutation, UserModel } from '../generated/graphql';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from './common/MenuDropdown';

const useUserRowStyles = createUseStyles({
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  closeModalBtn: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalSection: {
    marginBottom: '48px',
  },
  selectRole: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: '3px',
    color: Colors.MEDIUM_GRAY,
    marginLeft: '4px',
    padding: '9px',
    marginRight: '16px',
  },
  actionButton: {
    display: 'flex',
    flex: 1,
  },
  dropdownMenu: {
    display: 'inline-flex',
    flex: 1,
    marginRight: '16px',
  },
  emailInput: {
    height: '38px',
    width: '320px',
    fontSize: '14px',
    padding: '8px',
    marginRight: '16px',
  },
  userRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    alignItems: 'center',
    marginBottom: 15,
    marginLeft: 10,
  },
  userRowAvatar: {
    color: Colors.WHITE,
    fontWeight: 'bold',
    fontSize: '20px',
    height: 46,
    width: 46,
    lineHeight: '46px',
    textAlign: 'center',
    backgroundColor: Colors.MEDIUM_GRAY,
    borderRadius: 28,
    marginRight: 20,
  },
  userRowHeader: {
    fontSize: '14px',
    fontWeight: 700,
  },
  userRowNameHeader: {
    marginLeft: 66,
    marginRight: 10,
    width: 150,
  },
  userRowEmailHeader: {
    marginRight: 10,
    width: 260,
  },
  userRowRoleHeader: {
    marginRight: 10,
    textAlign: 'center',
    width: 80,
  },
  userRowName: {
    fontWeight: 700,
    marginRight: 10,
    width: 150,
  },
  userRowEmail: {
    color: Colors.MEDIUM_GRAY,
    marginRight: 10,
    width: 260,
  },
  userRowRole: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    width: 80,
    '& span': {
      backgroundColor: Colors.MEDIUM_GRAY,
      borderRadius: 3,
      color: Colors.WHITE,
      display: 'block',
      fontSize: '14px',
      padding: '6px 8px',
    },
  },
  userRowButton: {
    marginLeft: 'auto',
    marginRight: 10,
    '& button': {
      background: 'transparent',
      border: `1px solid ${Colors.LIGHT_BLUE}`,
      borderRadius: 3,
      color: Colors.LIGHT_BLUE,
      fontSize: '14px',
      padding: '5px 8px',
      cursor: 'pointer',
    },
  },
});

interface TeamCardProps {
  currentUser: UserModel | null;
  data: GetTeamUserListQuery | undefined;
  handleChangeRole: (
    options?: MutationFunctionOptions<SetRoleMutation, Exact<{ user_id: string; role: string }>> | undefined
  ) => void;
  handlePasswordReset: (options: any) => void;
  handleUserInvite: (options: any) => void;
  isUserInviteLoading: boolean;
  userInviteData: InviteUserMutation | null | undefined;
  isPasswordResetLoading: boolean;
  isChangeRoleLoading: boolean;
  modalIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const TeamCard: React.FunctionComponent<TeamCardProps> = ({
  data,
  handleChangeRole,
  handlePasswordReset,
  handleUserInvite,
  isUserInviteLoading,
  userInviteData,
  isPasswordResetLoading,
  isChangeRoleLoading,
  modalIsOpen,
  setIsOpen,
  currentUser,
}: TeamCardProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useUserRowStyles();
  const [role, setRole] = React.useState(TUserRole.ACCOUNTANT);
  const emailInputRef = React.useRef<HTMLInputElement>(null);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    if (emailInputRef && emailInputRef.current) {
      // reset input after success
      emailInputRef.current.value = '';
    }
  }, [userInviteData]);

  const formatRoleName = (role: string) => {
    if (role) {
      return role[0] + role.slice(1).toLocaleLowerCase();
    }
    return '';
  };

  const onRoleSelect = (role: TUserRole): void => setRole(role);

  const dropdownItems = Object.keys(TUserRole)
    .sort()
    .map((role) => ({ label: formatRoleName(role), onClick: () => onRoleSelect(TUserRole[role as TUserRole]) }));

  return (
    <div className={sharedClasses.contentWrapper}>
      <div className={sharedClasses.contentHeaderWrapper} style={{ justifyContent: 'flex-end' }}>
        {currentUser?.role === TUserRole.ADMIN ? (
          <FormButton onClick={openModal} width={150}>
            + Invite users now
          </FormButton>
        ) : null}
      </div>
      <div className={classes.userRow}>
        <div className={classnames(classes.userRowHeader, classes.userRowNameHeader)}>Name</div>
        <div className={classnames(classes.userRowHeader, classes.userRowEmailHeader)}>Email</div>
        <div className={classnames(classes.userRowHeader, classes.userRowRoleHeader)}>Role</div>
      </div>
      {data?.userList?.users &&
        data?.userList?.users?.map((user) => (
          <UserRow
            user={user as UserModel}
            key={user?.id}
            canEdit={currentUser?.role === TUserRole.ADMIN && currentUser?.id !== user?.id}
            handleChangeRole={handleChangeRole}
            handlePasswordReset={handlePasswordReset}
            isPasswordResetLoading={isPasswordResetLoading}
            isChangeRoleLoading={isChangeRoleLoading}
          />
        ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Invite User"
        style={{
          content: {
            width: '664px',
            height: '120px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
          },
        }}
      >
        <div className={classes.modalHeader}>
          <p>Invite User</p>
          <button onClick={closeModal} className={classes.closeModalBtn}>
            {'\u00d7'}
          </button>
        </div>
        <div className={classes.modalSection}>
          <input
            type="email"
            name="email"
            placeholder="you@company.com"
            ref={emailInputRef}
            className={classes.emailInput}
            disabled={isUserInviteLoading}
          />
          <div className={classes.dropdownMenu}>
            <MenuDropdown
              horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
              items={dropdownItems}
              isDisabled={isChangeRoleLoading}
            >
              {formatRoleName(role)}
            </MenuDropdown>
          </div>
          <FormButton
            width={160}
            onClick={() => {
              if (emailInputRef.current?.value) {
                handleUserInvite({ variables: { user_email: emailInputRef.current?.value, user_role: role } });
              }
            }}
            isDisabled={isUserInviteLoading}
          >
            Send Invite
          </FormButton>
        </div>
      </Modal>
    </div>
  );
};

export default TeamCard;

interface UserRowProps {
  user: UserModel;
  canEdit: boolean;
  handleChangeRole: (options: any) => void;
  handlePasswordReset: (options: any) => void;
  isPasswordResetLoading: boolean;
  isChangeRoleLoading: boolean;
}
const UserRow: React.FunctionComponent<UserRowProps> = ({
  canEdit,
  user,
  handleChangeRole,
  handlePasswordReset,
  isPasswordResetLoading,
  isChangeRoleLoading,
}: UserRowProps) => {
  const { notify } = useToaster(10000);
  const { id, firstName, lastName, role, email } = user;
  const [newRole, setNewRole] = React.useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const classes = useUserRowStyles();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const formatRoleName = (role: string) => {
    if (role) {
      return role[0] + role.slice(1).toLocaleLowerCase();
    }
    return '';
  };

  const dropdownItems = Object.keys(TUserRole)
    .sort()
    .map((role) => ({ label: formatRoleName(role), onClick: () => onRoleSelect(TUserRole[role as TUserRole]) }));

  const getDropdownLabel = () => (newRole ? formatRoleName(newRole) : formatRoleName(role));

  const onRoleSelect = (role: TUserRole): void => setNewRole(role);

  const getAvatarInitials = () => {
    if (firstName && lastName) {
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }
    return '';
  };

  const onUpdateRoleClick = (user_id: string, newRole: string) => {
    if (user_id && newRole) {
      const mutationVariables = { user_id, role: newRole };
      handleChangeRole({ variables: mutationVariables });
      notify(`Successfully updated ${firstName}'s role to ${newRole}`);
    }
  };

  const onHandlePasswordReset = async () => {
    handlePasswordReset({
      variables: {
        user_id: id,
      },
    });
    notify(`Password reset email sent! Check your inbox for instructions.`);
  };

  return (
    <div className={classes.userRow}>
      <div className={classes.userRowAvatar}>{getAvatarInitials()}</div>
      <div className={classes.userRowName}>{`${firstName} ${lastName}`}</div>
      <div className={classes.userRowEmail}>{email}</div>
      <div className={classes.userRowRole}>
        <span>{formatRoleName(role)}</span>
      </div>
      {canEdit ? (
        <div className={classes.userRowButton}>
          <button onClick={openModal}>Configure</button>
        </div>
      ) : null}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel={`${firstName} ${lastName} Configuration`}
        style={{
          content: {
            width: '450px',
            height: '340px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
          },
        }}
      >
        <div className={classes.modalHeader}>
          <p>{`${firstName} ${lastName} Configuration`}</p>
          <button onClick={closeModal} className={classes.closeModalBtn}>
            {'\u00d7'}
          </button>
        </div>
        <div className={classes.modalSection}>
          <h3>Role</h3>
          <div className={classes.actionButton}>
            <div className={classes.dropdownMenu}>
              <MenuDropdown
                horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                items={dropdownItems}
                isDisabled={isChangeRoleLoading}
              >
                {getDropdownLabel()}
              </MenuDropdown>
            </div>
            <FormButton
              extraStyle={{ flex: 1 }}
              onClick={() => onUpdateRoleClick(id, newRole)}
              isDisabled={isChangeRoleLoading}
            >
              Update Role
            </FormButton>
          </div>
        </div>
        <div className={classes.modalSection}>
          <h3>Security</h3>
          <FormButton
            extraStyle={{ width: '100%' }}
            onClick={onHandlePasswordReset}
            isDisabled={isPasswordResetLoading}
          >
            Reset Password
          </FormButton>
        </div>
      </Modal>
    </div>
  );
};
