import React from 'react';

import { MutationFunctionOptions } from '@apollo/client';

import Banner from './Banner';
import ErrorMessage from './common/ErrorMessage';
import TeamCard from './TeamCard';
import { useAppContext } from '../context/AppContext.tsx';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import { useToaster } from './common/Toaster/useToasterHook.tsx';
import {
  Exact,
  InviteUserMutation,
  ResetPasswordMutation,
  useGetTeamUserListQuery,
  useInviteUserMutation,
  useResetPasswordMutation,
  useSetRoleMutation,
} from '../generated/graphql';

const TableCardWithBanner: React.FunctionComponent = () => {
  const { currentUser } = useAppContext();
  const { currentCompany } = useCompanyContext();

  const { notify } = useToaster(10000);
  const sharedClasses = useSharedStyles();
  const [addUserModalIsOpen, setAddUserModalIsOpen] = React.useState(false);

  const {
    data,
    loading: isLoading,
    error,
  } = useGetTeamUserListQuery({
    skip: !currentCompany.id,
    variables: {
      entityId: currentCompany.id,
    },
  });

  const [changeRoleMutation, { loading: isChangeRoleLoading, error: changeRoleErrorStr }] = useSetRoleMutation();

  const [resetPasswordMutation, { loading: isPasswordResetLoading, error: passwordResetErrorStr }] =
    useResetPasswordMutation();

  const [inviteUserMutation, { data: userInviteData, loading: isUserInviteLoading, error: inviteUserErrorStr }] =
    useInviteUserMutation();

  if (error || passwordResetErrorStr || inviteUserErrorStr || changeRoleErrorStr) {
    return <ErrorMessage error={error || passwordResetErrorStr || inviteUserErrorStr || changeRoleErrorStr} />;
  }

  const onInviteUser = async (
    data: MutationFunctionOptions<InviteUserMutation, Exact<{ user_email: string; user_role: string }>> | undefined
  ) => {
    try {
      await inviteUserMutation(data);
      setAddUserModalIsOpen(false);
      notify('User invited! Please ask them to check their inbox or spam folder.');
    } catch (err) {
      notify('Notification stuck! Retry in a sec.');
    }
  };

  const onResetPassword = async (
    data: MutationFunctionOptions<ResetPasswordMutation, Exact<{ user_id: string }>> | undefined
  ) => {
    try {
      await resetPasswordMutation(data);
      notify('Password reset email sent! Please ask them to check their inbox or spam folder.');
    } catch (err) {
      notify('Password reset email stuck! Retry in a sec.');
    }
  };

  return (
    <div className={sharedClasses.main}>
      <Banner
        highlights={[
          {
            header: 'Company',
            value: currentUser?.company?.name || '',
            isLoading,
          },
          {
            header: 'Team Members',
            value: `${data?.userList?.users?.length}`,
            isLoading,
          },
        ]}
      />
      <TeamCard
        data={data}
        currentUser={currentUser}
        handleChangeRole={changeRoleMutation}
        handlePasswordReset={onResetPassword}
        handleUserInvite={onInviteUser}
        userInviteData={userInviteData}
        isUserInviteLoading={isUserInviteLoading}
        isPasswordResetLoading={isPasswordResetLoading}
        isChangeRoleLoading={isChangeRoleLoading}
        modalIsOpen={addUserModalIsOpen}
        setIsOpen={setAddUserModalIsOpen}
      />
    </div>
  );
};

export default TableCardWithBanner;
