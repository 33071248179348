import React, { useEffect } from 'react';

import { createUseStyles } from 'react-jss';
import { useLocation, useNavigate } from 'react-router-dom';

import LoaderAnimation from '../../../components/common/LoaderAnimation.tsx';
import { TNavItem } from '../../../../src/types/BaseTypes.ts';
import { getHomePageRoute } from '../../../utils/GetHomePageRoute.ts';
import { useAppContext } from '../../../context/AppContext.tsx';
import { CompanyModel, useCompanyMetadataQuery } from '../../../generated/graphql.tsx';

const useStyles = createUseStyles({
  loaderContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const RedirectToRevenue = () => {
  const styles = useStyles();
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: companyData, loading } = useCompanyMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: { reportName: TNavItem.REVENUE },
    skip: !isAuthenticated,
  });

  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        if (!companyData?.holdingCompany) {
          return;
        }
        const company = companyData?.holdingCompany.companies.find(
          (company) => company.id === companyData.holdingCompany.settings?.defaultCompanyId
        ) as CompanyModel;

        const { url, companyName } = getHomePageRoute(company?.name);
        navigate(`${url}${location.search}`, { replace: true, state: { companyName } });
      } catch (error) {
        console.error('Error fetching company name:', error);
      }
    };

    fetchCompanyName();
  }, [navigate, companyData?.holdingCompany, location]);

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <LoaderAnimation height={64} />
      </div>
    );
  }

  return null;
};

export default RedirectToRevenue;
