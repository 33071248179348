import { Classes } from 'jss';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import classnames from 'classnames';

import ArrayUtil from '../../../../src/utils/ArrayUtil';
import { ExtendedEntryRow } from '../../../features/pivotSettings/types.ts';
import FormatUtil from '../../../../src/utils/FormatUtil';
import StringUtil from '../../../../src/utils/StringUtil';
import { TCurrency } from '../../../../src/types/BaseTypes';
import { TLedgerAccountTypes } from '../../../../src/types/LedgerTypes';
import { CellEntry, EntryRow, RowGroup, TransactionCellRow } from '../../../generated/graphql';
import { DataGridFormatter, DataGridRow } from '../../../../src/types/DataGridTypes';

const PIXELS_PER_CHARACTER = 8;
const MIN_COLUMN_WIDTH = 50;
const DEFAULT_COLUMN_WIDTH = 120;

const cleanFieldKey = (str: string) => str.replace(/\./g, '__dot__');

export type FormatOptions = {
  forceDecimalPlaces: number;
  rawToDisplayPowerDifference: number;
};

export const calcColWidth = (valLength, col_width = DEFAULT_COLUMN_WIDTH) =>
  Math.max(MIN_COLUMN_WIDTH + (valLength * PIXELS_PER_CHARACTER) / 3, col_width || 0);

export const getColumns = (
  row: TransactionCellRow,
  includeNumeralIdCol: boolean,
  sharedClasses: Classes,
  timezoneLocation?: string | null,
  shouldAttemptToFitColumnHeader?: boolean
) => {
  const columns: TabulatorTypes.ColumnDefinition[] = [
    ...(includeNumeralIdCol
      ? [
          {
            title: 'Numeral ID',
            field: 'id',
            headerSort: false,
            cssClass: classnames(sharedClasses.linkUnderlined),
            formatter: 'link',
            formatterParams: {
              labelField: 'id',
              urlField: 'idUrl',
              customFormatter: DataGridFormatter.LINK_FORMATTER,
            },
            frozen: true,
            width: 160,
          } as TabulatorTypes.ColumnDefinition,
        ]
      : []),
    ...row?.cells?.map((cell) => {
      const formatterParams: TabulatorTypes.FormatterParams | undefined = cell.customFormatter
        ? { customFormatter: cell.customFormatter, timezone: timezoneLocation || undefined }
        : undefined;

      return {
        title: cell.label,
        field: cleanFieldKey(cell.field || cell.sortKey || cell.label),
        accessorParams:
          cell.dateFilterStartField && cell.dateFilterEndField
            ? {
                dateFilterEndField: cell.dateFilterEndField,
                dateFilterStartField: cell.dateFilterStartField,
              }
            : {},
        cssClass: classnames(
          cell.customFormatter === DataGridFormatter.LINK_FORMATTER && cell.url ? sharedClasses.linkUnderlined : '',
          cell.isLeftAligned ? '' : sharedClasses.contentCellRightAlign,
          sharedClasses.contentCellTextWrapped,
          cell.customClass?.trim()
        ),
        formatterParams,
        width: shouldAttemptToFitColumnHeader && cell.label ? calcColWidth(cell.label.length) : undefined,
        maxWidth: cell.customMaxWidth || undefined,
        frozen: !!cell.isSticky,
      } as TabulatorTypes.ColumnDefinition;
    }),
  ];
  return columns;
};

export const flattenData = (rows: TransactionCellRow[], idField = 'id') => {
  return (
    rows.map((row) => {
      return row.cells.reduce(
        (acc, cur) => {
          const { ...rest } = cur;
          const cleanedField = cleanFieldKey(cur.field || cur.sortKey || cur.label);
          const newCell = {
            ...acc,
            [cleanedField]: cur.content,
            _cellData: {
              ...acc._cellData,
              [cleanedField]: rest,
            },
            _children: row?.rows && flattenData((row?.rows || []) as TransactionCellRow[], idField),
            _rowCssClass: row.customClass,
          } as DataGridRow;
          return newCell;
        },
        { [idField]: row.label, idUrl: row.labelUrl, _cellData: { [idField]: { url: row.labelUrl } } } as DataGridRow
      );
    }) || []
  );
};

export const processNestedRows = (
  rows: ExtendedEntryRow[] | EntryRow[],
  formatOptions: FormatOptions,
  idField: string,
  isRealCustomerCompany?: boolean
) => {
  return rows?.length > 0
    ? rows.map((row) => {
        const totalValue = ArrayUtil.sum((row.cells || []).map((cell) => cell.amount || 0));
        const totalValueStr = FormatUtil.formatCurrencyAmount(totalValue, formatOptions);
        const totalValueFormatted = parseFloat(totalValueStr) < 0 ? `(${totalValueStr.substring(1)})` : totalValueStr;
        const gridRow = row?.cells?.reduce(
          (acc, cur) => {
            const { ...rest } = cur as CellEntry;
            const keyStr = cur.timespanStart
              ? new Date(cur.timespanStart)
                  .toLocaleDateString('en-US', { month: 'short', year: 'numeric', timeZone: 'UTC' })
                  .replace(/\s/g, '_')
              : 'unknown';
            const valueStr = FormatUtil.formatCurrencyAmount(Number(cur.amount), formatOptions);
            const newCell = {
              ...acc,
              [keyStr]: parseFloat(valueStr) < 0 ? `(${valueStr.substring(1)})` : valueStr,
              _cellData: {
                ...acc._cellData,
                [keyStr]: rest,
              },
              _cssClass: {
                ...acc._cssClass,
                [keyStr]: Math.round((cur.amount || 0) * 100) < 0 ? 'content-cell-negative' : '',
              },
              _children: row?.rows?.length ? [] : null,
            } as DataGridRow;
            return newCell;
          },
          {
            [idField]: row.label,
            sumUrl: row.sumUrl,
            dimensionValue: row.dimensionValue,
            shouldShowSumOfTimespans: row.shouldShowSumOfTimespans,
            total: row.shouldShowSumOfTimespans ? totalValueFormatted : undefined,
            _cellData: { total: { amount: 0, url: row.sumUrl } },
            _cssClass: {
              total: Math.round(totalValue * 100) < 0 ? 'content-cell-negative' : '',
            },
          } as DataGridRow
        );

        if (row.rows && !isRealCustomerCompany) {
          gridRow._children = processNestedRows(
            row.rows as ExtendedEntryRow[],
            {
              forceDecimalPlaces: 2,
              rawToDisplayPowerDifference: 2,
            },
            'name',
            isRealCustomerCompany
          );
        }

        return gridRow;
      })
    : undefined;
};

export const getFormatOptions = (currency: TCurrency): FormatOptions =>
  currency === TCurrency.USD
    ? {
        forceDecimalPlaces: 2,
        rawToDisplayPowerDifference: 2,
      }
    : {
        forceDecimalPlaces: 2,
        rawToDisplayPowerDifference: 0,
      };

export const nestData = (
  rowGroups: RowGroup[],
  currency: TCurrency,
  idField = 'name',
  isRealCustomerCompany?: boolean
) => {
  const formatOptions = getFormatOptions(currency);
  const output: unknown[] = [];
  let currentReportSection = '';
  rowGroups.forEach((grp) => {
    if (
      grp?.reportSection &&
      !grp?.shouldShowSumOfRows &&
      grp?.accountType !== TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE
    ) {
      currentReportSection = grp.reportSection;
    }
    if (
      grp?.reportSection &&
      grp.reportSection != currentReportSection &&
      grp?.accountType !== TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE
    ) {
      const newEntry = {
        name: grp?.reportSection,
        reportSection: currentReportSection,
        _children: undefined,
        _rowCssClass: 'non-group-header',
      };
      output.push(newEntry);
    }
    const totalValueGrp = ArrayUtil.sum((grp?.overrideCells || []).map((cell) => cell.amount || 0));
    const totalValueGrpStr = FormatUtil.formatCurrencyAmount(totalValueGrp, formatOptions);
    const totalValueGrpFormatted =
      parseFloat(totalValueGrpStr) < 0 ? `(${totalValueGrpStr.substring(1)})` : totalValueGrpStr;

    const groupRows = processNestedRows(grp?.rows as ExtendedEntryRow[], formatOptions, idField, isRealCustomerCompany);
    let outputProps: DataGridRow = {};
    if (grp?.shouldShowSumOfRows && groupRows) {
      const groupRowTotal = groupRows.reduce(
        (acc, cur) => {
          const { _cellData, dimensionValue, sumUrl } = cur as DataGridRow;
          if (acc) {
            Object.keys(_cellData as object).forEach((m) => {
              acc[m] = (Number(acc?.[m]) || 0) + Number(_cellData?.[m].amount);
              acc.total = (Number(acc.total) || 0) + Number(_cellData?.[m].amount);
              acc._cellData.total.amount = acc.total;
              if (typeof dimensionValue === 'string' && !acc.dimensionValue.includes(dimensionValue)) {
                acc.dimensionValue.push(dimensionValue);
              }
              if (acc.dimensionValue.length && _cellData?.[m]?.url) {
                acc._cellData[m] = {
                  url: StringUtil.replaceParamInUrl(`${_cellData[m].url}`, 'dv', acc.dimensionValue.join(',')),
                };
              }
              if (acc.dimensionValue.length && sumUrl) {
                acc._cellData.total.url = StringUtil.replaceParamInUrl(`${sumUrl}`, 'dv', acc.dimensionValue.join(','));
              }
            });
          }
          return acc;
        },
        { total: 0, dimensionValue: [] as string[], _cellData: { total: { amount: 0, url: '' } } }
      );
      const groupRowTotalFormatted = Object.keys(groupRowTotal as object)?.reduce((acc, cur) => {
        if (typeof groupRowTotal?.[cur] === 'number') {
          const valueStr = FormatUtil.formatCurrencyAmount(Number(groupRowTotal?.[cur]), formatOptions);
          return {
            ...acc,
            [cur]: parseFloat(valueStr) < 0 ? `(${valueStr.substring(1)})` : valueStr,
          };
        }
        return {
          ...acc,
          [cur]: groupRowTotal?.[cur],
        };
      }, {});
      outputProps = groupRowTotalFormatted;
    } else if (grp?.overrideCells) {
      const groupOverrides = grp?.overrideCells?.reduce(
        (acc, cur) => {
          const keyStr = cur.timespanStart
            ? new Date(cur.timespanStart)
                .toLocaleDateString('en-US', { month: 'short', year: 'numeric', timeZone: 'UTC' })
                .replace(/\s/g, '_')
            : 'unknown';
          const valueStr = FormatUtil.formatCurrencyAmount(Number(cur.amount), formatOptions);

          if (cur?.url) {
            acc._cellData[keyStr] = { url: cur.url };
          }
          return {
            ...acc,
            [keyStr]: parseFloat(valueStr) < 0 ? `(${valueStr.substring(1)})` : valueStr,
          };
        },
        {
          total: grp.shouldShowSumOfTimespans ? totalValueGrpFormatted : undefined,
          _cellData: { total: { amount: 0, url: '' } },
        }
      );
      outputProps = groupOverrides as unknown as DataGridRow;
    }

    output.push({
      name: grp.label,
      reportSection: currentReportSection,
      ...outputProps,
      _cssClass: {
        total: Math.round(totalValueGrp * 100) < 0 ? 'content-cell-negative' : '',
        ...grp?.overrideCells?.reduce((acc, cur) => {
          const keyStr = `${cur.label}_${String(cur.timespanStart).substring(0, 4)}`;
          return {
            ...acc,
            [keyStr]: Math.round((cur.amount || 0) * 100) < 0 ? 'content-cell-negative' : '',
          };
        }, {}),
      },
      _children: groupRows,
      _rowCssClass: 'tree-level-0-header',
    });
  });
  return output;
};
