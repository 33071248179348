import React, { ReactElement } from 'react';

import { createUseStyles } from 'react-jss';

import Colors from '../common/Colors.tsx';
import KeyValueList from '../common/KeyValueList';
import { Payout } from '../../generated/graphql';
import SectionNavHeader from '../SectionNavHeader';
import SectionWithHeader from '../common/SectionWithHeader';
import { useSharedStyles } from '../../utils/CssUtil';

const useStyles = createUseStyles({
  overviewSection: {
    '& *': {
      fontSize: '13px',
    },
    '& a, & a:hover, & a:visited': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dashed',
    },
  },
  triggeredRule: {
    backgroundColor: Colors.LIGHT_GRAY,
    boxSizing: 'border-box',
    padding: '15px',
    borderRadius: '3px',
    lineHeight: 1.3,
    marginBottom: '10px',
    width: '100%',

    '&:last-child': {
      marginBottom: 0,
    },

    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
      textDecoration: 'underline dashed',
    },
  },
});

type PaymentRecognitionScheduleCardPayoutProps = {
  isRealCustomerCompany?: boolean;
  payout?: Payout & { transactionId: string };
  reportSettingsExclude?: string[] | null;
  shouldSkipEventsSection?: boolean;
  isCashReconciliationEnabled?: boolean;
  timezoneLocation?: string;
  companyName?: string;
};
const PaymentRecognitionScheduleCardPayout: React.FunctionComponent<PaymentRecognitionScheduleCardPayoutProps> = ({
  payout,
}: PaymentRecognitionScheduleCardPayoutProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  if (!payout) {
    return null;
  }
  const keyValuePairs = [
    {
      key: 'Direction',
      value: payout.direction || 'N/A',
    },
    {
      key: 'Vendor',
      value: payout.vendor || 'N/A',
    },
    {
      key: 'Settlement Date',
      value: payout.settlementDate,
    },
    {
      key: 'Initiation Date',
      value: payout.initiationDate,
    },
    {
      key: `Gross Amount`,
      value: payout.formattedAmount,
    },
  ];

  let payoutContainer: ReactElement | undefined = undefined;

  payoutContainer = (
    <div style={{ marginTop: '10px ' }}>
      <div className={sharedClasses.contentHeaderWrapper}>
        <SectionNavHeader
          sections={[
            {
              label: 'Payout',
            },
          ]}
        />
        <div className={sharedClasses.contentHeaderUtils} />
      </div>

      <div className={classes.overviewSection} key={`payout-${payout.transactionId}`}>
        <SectionWithHeader header={`Payout: ${payout.transactionId}`}>
          <KeyValueList keyValuePairs={keyValuePairs} />
        </SectionWithHeader>
      </div>
    </div>
  );

  return <>{payoutContainer}</>;
};

export default PaymentRecognitionScheduleCardPayout;
