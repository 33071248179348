import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import Colors from '../common/Colors.tsx';
import KeyValueList from '../common/KeyValueList';
import SectionNavHeader from '../SectionNavHeader';
import SectionWithHeader from '../common/SectionWithHeader';
import { Refund, TransactionRecognitionTableInvoiceFragmentFragment } from '../../generated/graphql';
import { useSharedStyles } from '../../utils/CssUtil';
import { DateFormatYearMonthDate12HourTime } from '../../../src/utils/FormatUtil.ts';

const useStyles = createUseStyles({
  overviewSection: {
    '& *': {
      fontSize: '13px',
    },
    '& a, & a:hover, & a:visited': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dashed',
    },
  },
  triggeredRule: {
    backgroundColor: Colors.LIGHT_GRAY,
    boxSizing: 'border-box',
    padding: '15px',
    borderRadius: '3px',
    lineHeight: 1.3,
    marginBottom: '10px',
    width: '50%',

    '&:last-child': {
      marginBottom: 0,
    },

    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
      textDecoration: 'underline dashed',
    },
  },
});

type PaymentRecognitionScheduleCardRefundProps = {
  isRealCustomerCompany?: boolean;
  refund?: Refund;
  reportSettingsExclude?: string[] | null;
  shouldSkipEventsSection?: boolean;
  isCashReconciliationEnabled?: boolean;
  timezoneLocation?: string;
  companyName?: string;
  invoice?: TransactionRecognitionTableInvoiceFragmentFragment;
  transactionId?: string;
};
const PaymentRecognitionScheduleCardRefund: React.FunctionComponent<PaymentRecognitionScheduleCardRefundProps> = ({
  isRealCustomerCompany,
  refund,
  reportSettingsExclude,
  shouldSkipEventsSection,
  isCashReconciliationEnabled,
  timezoneLocation,
  companyName,
  invoice,
  transactionId,
}: PaymentRecognitionScheduleCardRefundProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  if (!refund) {
    return null;
  }
  const issuedAt = moment(Number(refund.issuedAt));
  if (timezoneLocation) {
    issuedAt.tz(timezoneLocation);
  }

  let refundContainer: ReactElement | undefined = undefined;
  const keyValuePairs = [
    {
      key: 'Source',
      value: refund.source || 'N/A',
    },
    {
      key: 'IssuedAt',
      value: issuedAt.format(DateFormatYearMonthDate12HourTime) || 'N/A',
    },
    {
      key: 'customerId',
      value: refund.entity.customerId || 'N/A',
    },
    {
      key: `description`,
      value: refund.entity.description || 'N/A',
    },
    {
      key: `Refunded Amount`,
      value:
        refund.entity.formattedAmount === refund.entity.formattedAmountBaseCurrency
          ? refund.entity.formattedAmountBaseCurrency
          : refund.entity.formattedAmountBaseCurrency + ` (${refund.entity.formattedAmount})`,
    },
  ];
  refundContainer = (
    <div style={{ marginTop: '10px ' }}>
      <div className={sharedClasses.contentHeaderWrapper}>
        <SectionNavHeader
          sections={[
            {
              label: 'Refund',
            },
          ]}
        />
        <div className={sharedClasses.contentHeaderUtils} />
      </div>
      <div className={classes.overviewSection} key={`Refund-${transactionId}`}>
        <SectionWithHeader header={`Refund: ${transactionId}`}>
          <KeyValueList keyValuePairs={keyValuePairs} />
        </SectionWithHeader>
      </div>
    </div>
  );

  return <>{refundContainer}</>;
};

export default PaymentRecognitionScheduleCardRefund;
