import React, { useEffect } from 'react';

import { generatePath, useMatch, useNavigate } from 'react-router-dom';

import RevenueCardWithBanner from './RevenueCardWithBanner';
import { getHomePageRoute } from '../utils/GetHomePageRoute.ts';
import { useCompanyContext } from '../context/CompanyContext.tsx';

const TransformedRevenue: React.FC = () => {
  const { isSupportTransformedRevenue, currentCompany } = useCompanyContext();
  const navigate = useNavigate();
  const isTransformedRoute = !!useMatch('/app/transactions');

  useEffect(() => {
    if (!isTransformedRoute || !currentCompany.name) {
      return;
    }

    if (!isSupportTransformedRevenue) {
      const route = getHomePageRoute(currentCompany.name);
      navigate(generatePath(route.url), {
        replace: true,
        state: {
          companyName: route.companyName,
        },
      });
    }
  }, [currentCompany.name, isSupportTransformedRevenue, isTransformedRoute, navigate]);

  if (isSupportTransformedRevenue) {
    return <RevenueCardWithBanner />;
  }

  return null;
};

export default TransformedRevenue;
