import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import { useSharedStyles } from '../../utils/CssUtil.ts';

type ErrorMessageProps = {
  error?: ApolloError | string;
};

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ error }: ErrorMessageProps) => {
  const sharedClasses = useSharedStyles({});
  const navigate = useNavigate();

  if (!error) return null;
  let message = error;

  if (typeof error !== 'string') {
    message = error?.graphQLErrors?.[0]?.message;
  }

  if (typeof error !== 'string' && error.message.includes('Not signed in')) {
    setTimeout(() => {
      navigate('/');
    }, 100);
  }
  // only show first error message from graphQLErrors list
  return <div className={sharedClasses.main}>Error: {String(message)}</div>;
};

export default ErrorMessage;
