import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import ApolloClient from '../../apollo/ApolloClient.ts';
import { AppProvider } from '../../context/AppContext.tsx';
import { AppRoutes } from './AppRoutes.tsx';

const AppContainer = () => (
  <ApolloProvider client={ApolloClient}>
    <BrowserRouter>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </BrowserRouter>
  </ApolloProvider>
);

export default AppContainer;
