import React, { useState } from 'react';

import Lottie from 'react-lottie-player';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import { gql, useMutation } from '@apollo/client';

import Colors from '../../common/Colors';
import Modal from '../../common/Modal';
import { getHomePageRoute } from '../../../utils/GetHomePageRoute.ts';
import loading_animation from '../../../../public/animations/loading_animation.json';
import success_animation from '../../../../public/animations/success_animation.json';
import { useCompanyContext } from '../../../context/CompanyContext.tsx';
import FormButton, { FormButtonStyle } from '../../common/FormButton';
import FormatUtil, { DateFormatLongMonthYear } from '../../../../src/utils/FormatUtil';
import {
  LockMonthMutation,
  LockMonthMutationVariables,
  SetLockMonthMutation,
  SetLockMonthMutationVariables,
} from '../../../generated/graphql';

const useStyles = createUseStyles({
  modalHeaderTitle: {
    margin: '12px 0',
  },
  modalBodyLock: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  modalBodyAnimation: {
    width: 150,
    height: 150,
    position: 'absolute',
    left: 80,
  },
  modalBodyLockLabel: {
    padding: '20px 0 20px 35px',
  },
  modalBodyDefault: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.6px',
    color: '#8597B4',
  },
  modalFooter: {
    display: 'flex',
    flexFlow: 'row-reverse',
    justifyContent: 'flex-start',
    gap: 125,
    marginTop: '10px',
    '& > *': {
      '&:first-child': {
        marginLeft: '0',
      },
    },
  },
});

const MUTATION_SET_LOCK_MONTH_OLD = gql`
  mutation LockMonth($lockMonthString: String!) {
    lockMonth(lockMonthString: $lockMonthString) {
      isSuccessful
    }
  }
`;

const MUTATION_SET_LOCK_MONTH_NEW = gql`
  mutation SetLockMonth($yearMonth: String!) {
    setLockMonth(yearMonth: $yearMonth) {
      yearMonth
    }
  }
`;

type DataGridColumnLockMonthModalProps = {
  lockMonthStr: string | undefined;
  toggleVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  useNewMethod: boolean | undefined;
};

const DataGridColumnLockMonthModal: React.FunctionComponent<DataGridColumnLockMonthModalProps> = ({
  lockMonthStr,
  toggleVisibility,
  useNewMethod,
}: DataGridColumnLockMonthModalProps) => {
  const { currentCompany } = useCompanyContext();
  const classes = useStyles();

  const [makeMutationOld, { loading: isMutationLoadingOld, error: mutationErrorStrOld }] = useMutation<
    LockMonthMutation,
    LockMonthMutationVariables
  >(MUTATION_SET_LOCK_MONTH_OLD);

  const [makeMutation, { loading: isMutationLoading, error: mutationErrorStr }] = useMutation<
    SetLockMonthMutation,
    SetLockMonthMutationVariables
  >(MUTATION_SET_LOCK_MONTH_NEW);

  const [lockMonthSuccessMessage, updateLockMonthSuccessMessage] = useState<string | undefined>();
  const [lockMonthError, updateLockMonthError] = useState<string | undefined>();

  let displayLockMonthStr: string | undefined = undefined;
  if (lockMonthStr) {
    displayLockMonthStr = moment(FormatUtil.monthStrToDate(lockMonthStr)).format(DateFormatLongMonthYear);
  }

  const mutationLoading = useNewMethod ? isMutationLoading : isMutationLoadingOld;
  const lockLoadingState = lockMonthSuccessMessage || mutationLoading;
  const lockError = lockMonthError || (useNewMethod ? mutationErrorStr : mutationErrorStrOld);
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => toggleVisibility(false)}
      contentLabel={`Lock ${displayLockMonthStr}?`}
      style={{
        content: {
          width: '400px',
          height: '235px',
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          boxSizing: 'border-box',
          padding: '25px 33px',
          borderRadius: 5,
        },
      }}
    >
      <div>
        <h3 className={classes.modalHeaderTitle}>Lock {displayLockMonthStr}</h3>
        <hr />
        {lockLoadingState ? (
          <div className={classes.modalBodyLock}>
            <Lottie
              loop
              animationData={lockMonthSuccessMessage ? success_animation : loading_animation}
              play
              className={classes.modalBodyAnimation}
            />
            <span className={classes.modalBodyLockLabel}>
              {lockMonthSuccessMessage ? lockMonthSuccessMessage : 'Loading...'}
            </span>
          </div>
        ) : (
          <p className={classes.modalBodyDefault}>
            Any adjustments to this period will automatically roll forward to the next open period.{' '}
            <strong>This action cannot be undone.</strong>
          </p>
        )}
        <hr />
        {lockError ? <p style={{ color: Colors.WARNING_RED }}>{String(lockError)}</p> : null}
        <div className={classes.modalFooter}>
          {lockMonthSuccessMessage !== 'Success!' ? (
            <FormButton
              style={FormButtonStyle.ACCENTED}
              isDisabled={isMutationLoading}
              onClick={() => {
                updateLockMonthError(undefined);
                const defaultError = 'Unable to lock month, try again later';

                if (useNewMethod) {
                  makeMutation({ variables: { yearMonth: lockMonthStr || '' } }).then((resp) => {
                    if (resp.errors) {
                      updateLockMonthError(defaultError);
                    } else if (resp.data?.setLockMonth?.length) {
                      updateLockMonthSuccessMessage('Success!');
                    } else {
                      updateLockMonthError(defaultError);
                    }
                  });
                } else {
                  makeMutationOld({ variables: { lockMonthString: lockMonthStr || '' } }).then((resp) => {
                    if (resp.errors) {
                      updateLockMonthError(defaultError);
                    } else if (resp.data?.lockMonth?.isSuccessful) {
                      updateLockMonthSuccessMessage('Success!');
                    } else {
                      updateLockMonthError(defaultError);
                    }
                  });
                }
              }}
              width={130}
            >
              Lock Month
            </FormButton>
          ) : (
            <FormButton
              style={FormButtonStyle.ACCENTED}
              onClick={() => {
                updateLockMonthError(undefined);
                updateLockMonthSuccessMessage(undefined);
                toggleVisibility(false);
                // refresh page
                document.location.pathname = getHomePageRoute(currentCompany.name).url;
              }}
              width={130}
            >
              Got it!
            </FormButton>
          )}
          {mutationLoading || lockMonthSuccessMessage === 'Success!' ? null : (
            <FormButton
              style={FormButtonStyle.PLAIN}
              onClick={() => toggleVisibility(false)}
              width={76}
              extraStyle={{ border: 'none' }}
            >
              Go Back
            </FormButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DataGridColumnLockMonthModal;
