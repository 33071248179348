import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { TLoginStep } from '../../src/types/BaseTypes.ts';
import { publicRoutes } from '../utils/Constants.ts';
import { useAuth } from '../hooks/useAuth.ts';
import { GetCurrentSessionQuery, LoginResponse, Session, UserModel } from '../generated/graphql';

type AppContextData = GetCurrentSessionQuery & {
  appName: string;
  onResetAuth: () => void;
  isAuthenticated: boolean;
  currentUser: UserModel | null;
  onLoginSuccess: (user: LoginResponse) => Promise<void>;
};

interface AppProviderProps {
  children: ReactNode;
}

const AppContext = React.createContext<AppContextData>({} as AppContextData);

export const AppProvider = ({ children }: AppProviderProps) => {
  const navigate = useNavigate();
  const { fetchAuthDetails, isLoading, currentSessionData, currentUserData } = useAuth();
  const { pathname } = useLocation();
  const isNonAuthRoute = publicRoutes.includes(pathname);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const getFromLocalStorage = <T,>(key: string): T | null => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const bootstrapApp = useCallback(async () => {
    const sessionData = getFromLocalStorage<Session>('currentSession');
    const userData = getFromLocalStorage<UserModel>('currentUser');
    if (sessionData && userData) {
      setAuthenticated(true);
    } else {
      await fetchAuthDetails();
      setAuthenticated(true);
    }
  }, [fetchAuthDetails]);

  const onLoginSuccess = useCallback(
    async (user: LoginResponse) => {
      await fetchAuthDetails();
      setAuthenticated(true);
      if (user?.loginStep !== TLoginStep.COMPLETED) {
        navigate('/login-otp');
      }
    },
    [fetchAuthDetails, navigate]
  );

  useEffect(() => {
    if (!isNonAuthRoute) {
      bootstrapApp();
    }
  }, [bootstrapApp, isNonAuthRoute]);

  const onResetAuth = () => {
    setAuthenticated(false);
    localStorage.removeItem('currentSession');
    localStorage.removeItem('currentUser');
  };

  const cachedCurrentUser = (currentUserData?.currentUser ||
    getFromLocalStorage<UserModel>('currentUser') ||
    {}) as UserModel;

  const cachedCurrentSession = (currentSessionData ||
    getFromLocalStorage<GetCurrentSessionQuery>('currentSession') ||
    {}) as GetCurrentSessionQuery;

  return (
    <AppContext.Provider
      value={{
        appName: 'Numeral',
        isAuthenticated,
        onResetAuth,
        onLoginSuccess,
        currentUser: cachedCurrentUser || {},
        currentSession: cachedCurrentSession?.currentSession || {},
        currentAvailableNavSidebars: cachedCurrentSession?.currentAvailableNavSidebars ?? [],
        currentNavSidebarsCollapsed: cachedCurrentSession?.currentNavSidebarsCollapsed ?? [],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextData => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};

export default AppContext;
