import React from 'react';

import Modal from 'react-modal';
import { Route, Routes } from 'react-router-dom';

import { App } from './App.tsx';
import AuditTrail from '../../components/AuditTrail.tsx';
import Banner from '../../components/Banner.tsx';
import CashTransactionsCardWithBanner from '../../components/CashTransactionsCardWithBanner.tsx';
import CompanySettings from '../../components/CompanySettings.tsx';
import DataExceptionsWithBanner from '../../components/DataExceptionsWithBanner.tsx';
import DataHubCard from '../../components/DataHubCard.tsx';
import DataHubLineItemCardWithBanner from '../../components/DataHubLineItemCardWithBanner.tsx';
import DataHubSingleItemCard from '../dataHub/pages/DataHubSingleItemCard.tsx';
import DepositsCardWithBanner from '../../components/DepositsCardWithBanner.tsx';
import EmailSent from '../../components/EmailSent.tsx';
import Exceptions from '../../components/Exceptions.tsx';
import ExceptionsDrilldownCardWithBanner from '../../components/ReconciliationExceptionsDrilldownCardWithBanner.tsx';
import ForgotPassword from '../../components/ForgotPassword.tsx';
import IntegrationsCard from '../../components/IntegrationsCard.tsx';
import Join from '../../components/Join.tsx';
import Login from '../../components/Login.tsx';
import LoginOtp from '../../components/LoginOTP.tsx';
import Maintenance from '../../components/Maintenance.tsx';
import NetsuiteConnect from '../../components/NetsuiteConnectContainer.tsx';
import PasswordResetSuccess from '../../components/PasswordResetSuccess.tsx';
import PaymentRecognitionScheduleCardWithBanner from '../../components/PaymentRecognitionScheduleCardWithBanner.tsx';
import PlaidLink from '../../components/PlaidLinkContainer.tsx';
import PriceListCard from '../../components/price_list/PriceListCard.tsx';
import PriceListNewBundle from '../../components/price_list/PriceListNewBundle.tsx';
import ReconciliationCardWithBanner from '../../components/ReconciliationCardWithBanner.tsx';
import ReconciliationExceptionsDrilldownJsonWithBanner from '../../components/ReconciliationExceptionsDrilldownJsonWithBanner.tsx';
import RedirectToRevenue from './components/RedirectToRevenue.tsx';
import ReportsCard from '../../components/reports/ReportsCard.tsx';
import ResetPassword from '../../components/ResetPassword.tsx';
import RevenueCardWithBanner from '../../components/RevenueCardWithBanner.tsx';
import RulesCard from '../../components/rules/RulesCard.tsx';
import SecuritySettings from '../../components/SecuritySettings.tsx';
import { SideBarProvider } from '../sideBar/providers/SideBarProvider.tsx';
import TeamWithBanner from '../../components/TeamWithBanner.tsx';
import TransactionsCardWithBanner from '../../components/TransactionsCardWithBanner.tsx';
import TransformedRevenue from '../../components/TransformedRevenue.tsx';
import { useAppContext } from '../../context/AppContext.tsx';
import { useSharedStyles } from '../../utils/CssUtil.ts';

Modal.setAppElement('#container');

const AuthenticatedRoutes = React.memo(() => {
  const sharedClasses = useSharedStyles();
  return (
    <Routes>
      <Route
        path="/connect/plaid/:encryptedCompanyId"
        element={
          <div className={sharedClasses.main}>
            <Banner hideUserNav />
            <PlaidLink />
          </div>
        }
      />
      <Route
        path="/connect/netsuite"
        element={
          <div className={sharedClasses.main}>
            <Banner hideUserNav />
            <NetsuiteConnect />
          </div>
        }
      />
      <Route
        path="/connect/plaid/:encryptedCompanyId/update/:institutionName"
        element={
          <div className={sharedClasses.main}>
            <Banner hideUserNav />
            <PlaidLink isUpdateMode />
          </div>
        }
      />

      <Route path="*" element={<RedirectToRevenue />} />
      <Route path="/app/:companyName/" element={<App />}>
        <Route path="data-hub" element={<DataHubCard />} />
        <Route path="data-hub/line-item/:lineItemId" element={<DataHubLineItemCardWithBanner />} />
        <Route path="data-hub/single-item/:type/:id/" element={<DataHubSingleItemCard />} />
        <Route path="reports" element={<ReportsCard />} />
        <Route path="revenue" element={<RevenueCardWithBanner />} />
        <Route path="reconciliation" element={<ReconciliationCardWithBanner />} />
        <Route path="missing-data" element={<DataExceptionsWithBanner />} />
        <Route path="transactions" element={<TransformedRevenue />} />
        <Route path="revenue/transactions" element={<TransactionsCardWithBanner />} />
        <Route path="revenue/transaction" element={<PaymentRecognitionScheduleCardWithBanner />} />
        <Route path="revenue/cash-transactions" element={<CashTransactionsCardWithBanner />} />
        <Route path="deposits" element={<DepositsCardWithBanner />} />
        <Route path="expenses" element={<div className={sharedClasses.main}>Expenses go here</div>} />
        <Route path="audit-trail" element={<AuditTrail />} />
        <Route path="exceptions" element={<Exceptions />} />
        <Route path="reconciliations/exceptions" element={<ExceptionsDrilldownCardWithBanner />} />
        <Route
          path="reconciliations/exceptions/:cashTxnId"
          element={<ReconciliationExceptionsDrilldownJsonWithBanner />}
        />
        <Route
          path="integrations"
          element={
            <div className={sharedClasses.main}>
              <Banner />
              <IntegrationsCard />
            </div>
          }
        />
        <Route
          path="plaid-link"
          element={
            <div className={sharedClasses.main}>
              <Banner />
              <PlaidLink isBehindLogin />
            </div>
          }
        />
        <Route
          path="rules"
          element={
            <div className={sharedClasses.main}>
              <Banner />
              <RulesCard />
            </div>
          }
        />
        <Route path="price-list" element={<PriceListCard />} />
        <Route path="price-list/new-bundle" element={<PriceListNewBundle />} />
        <Route path="team" element={<TeamWithBanner />} />
        <Route path="company-settings" element={<CompanySettings />} />
        <Route
          path="security_settings"
          element={
            <div className={sharedClasses.main}>
              <Banner />
              <SecuritySettings />
            </div>
          }
        />
      </Route>
    </Routes>
  );
});

export const AppRoutes = React.memo(() => {
  const { isAuthenticated } = useAppContext();

  return (
    <SideBarProvider>
      <Routes>
        {isAuthenticated ? (
          <Route path="*" element={<AuthenticatedRoutes />} />
        ) : (
          <>
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/login-otp" element={<LoginOtp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-success" element={<PasswordResetSuccess />} />
            <Route path="/join" element={<Join />} />
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </SideBarProvider>
  );
});
