import { useEffect, useState } from 'react';

export const useSectionOpenHook = () => {
  const [isInitialSectionOpen, updateIsInitialSectionOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;
    window.setTimeout(() => {
      if (isMounted && !isInitialSectionOpen) {
        updateIsInitialSectionOpen(true);
      }
    }, 500);
    return () => {
      isMounted = false;
    };
  }, []);

  return [isInitialSectionOpen, updateIsInitialSectionOpen];
};
