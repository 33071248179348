export enum CompanyName {
  ALLTRAILS = 'AllTrails',
  BATTLBOX = 'BattlBox',
  CAPTIONSAI = 'CaptionsAI',
  CLOSE = 'Close',
  ELYSIUM = 'Elysium',
  GABB = 'Gabb',
  HEALTHIE = 'Healthie',
  HIPCAMP = 'Hipcamp',
  PAYZEN = 'PayZen',
  PADLET = 'Padlet',
  SCRIBD = 'Scribd',
  SCRIBD_NATIVE = 'ScribdNative',
  ALLTRUE = 'Alltrue',
  BEACHLY = 'Beachly',
  BIRCHBOX = 'Birchbox',
}

export enum HoldingCompanyName {
  RETENTION = 'Retention',
  SCRIBD = 'Scribd',
  ALLTRAILS = 'AllTrails',
  GABB = 'Gabb',
  HEALTHIE = 'Healthie',
  CLOSE = 'Close',
  CAPTIONSAI = 'CaptionsAI',
  BATTLBOX = 'BattlBox',
  ELYSIUM = 'Elysium',
  HIPCAMP = 'Hipcamp',
  PAYZEN = 'PayZen',
  PADLET = 'Padlet',
}
