import React from 'react';

import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';

import ArrayUtil from '../../src/utils/ArrayUtil';
import Banner from './Banner';
import { DateFormatYearMonthDateTime } from '../../src/utils/FormatUtil';
import ErrorMessage from './common/ErrorMessage';
import KeyValueList from './common/KeyValueList';
import LoaderAnimation from './common/LoaderAnimation';
import PrettyJSON from './common/PrettyJSON';
import SectionWithHeader from './common/SectionWithHeader';
import { TVendor } from '../../src/types/BaseTypes';
import TabbedContainers from './common/TabbedContainers';
import { getJSONHeight } from '../features/dataHub/utils/getJSONHeight';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import { useGetSingleInvoiceItemQuery } from '../generated/graphql';
import { useSectionOpenHook } from '../features/dataHub/hooks/useSectionOpenHook.ts';
import { useSharedStyles } from '../utils/CssUtil';
import { useStyles } from '../features/dataHub/styles';
import { DataHubSingleItemCardProps, Section } from '../features/dataHub/types.ts';

const DataHubLineItemCardWithBanner: React.FunctionComponent<DataHubSingleItemCardProps> = ({
  initialOpenSection = Section.LineItem,
}: DataHubSingleItemCardProps) => {
  const sharedClasses = useSharedStyles();
  const params = useParams();
  const { currentCompany } = useCompanyContext();
  const invoiceItemId = params.lineItemId || '';

  const classes = useStyles();

  const [isInitialSectionOpen] = useSectionOpenHook();

  const {
    loading: isLoading,
    error,
    data,
  } = useGetSingleInvoiceItemQuery({
    variables: { id: invoiceItemId, entityId: currentCompany.id },
  });

  // Because the array is frozen in strict mode, need to copy the array before sorting it
  const paymentApplications = data
    ? (data.invoiceItemDetails.invoice?.paymentApplications || [])
        .slice()
        .sort(
          (a, b) => new Date(a.paymentEntity.completedAt).getTime() - new Date(b.paymentEntity.completedAt).getTime()
        )
    : [];
  const refunds = ArrayUtil.flatten(paymentApplications.map((ea) => ea.refunds || []));

  const invoiceJSONObject = data
    ? data.invoiceItemDetails.invoice?.rawJSON
      ? JSON.parse(data.invoiceItemDetails.invoice?.rawJSON)
      : undefined
    : undefined;

  const oandaJSONObject = data
    ? data.invoiceItemDetails.oandaRawJSON
      ? JSON.parse(data.invoiceItemDetails.oandaRawJSON)
      : undefined
    : undefined;

  const paymentApplicationJSONObjects = paymentApplications.map((p) => (p.rawJSON ? JSON.parse(p.rawJSON) : undefined));
  const transactionJSONMaxLines = Math.max(
    ...[invoiceJSONObject]
      .concat(paymentApplicationJSONObjects)
      .filter((obj) => !!obj)
      .map((obj) => Object.keys(obj).length)
  );
  const transactionJSONHeightPx = getJSONHeight(transactionJSONMaxLines);

  const invoiceItemJSONObject = data
    ? data.invoiceItemDetails.rawJSON
      ? JSON.parse(data.invoiceItemDetails.rawJSON)
      : undefined
    : undefined;
  const invoiceItemJSONHeightPx = getJSONHeight(invoiceItemJSONObject ? Object.keys(invoiceItemJSONObject).length : 0);

  const contract = data?.invoiceItemDetails?.invoice?.contract;
  const contractJSONObject = data
    ? data.invoiceItemDetails.invoice?.contract?.rawJSON
      ? JSON.parse(data.invoiceItemDetails.invoice?.contract?.rawJSON)
      : undefined
    : undefined;
  const contractJSONHeightPx = getJSONHeight(contractJSONObject ? Object.keys(contractJSONObject).length : 0);

  const customer = contract?.customer;
  const customerJSONObject = data
    ? data.invoiceItemDetails.invoice?.contract?.customer?.rawJSON
      ? JSON.parse(data.invoiceItemDetails.invoice?.contract?.customer?.rawJSON)
      : undefined
    : undefined;
  const customerJSONHeightPx = getJSONHeight(customerJSONObject ? Object.keys(customerJSONObject).length : 0);

  const credit = data?.invoiceItemDetails.credit;

  const invoiceVendor = data ? data.invoiceItemDetails.vendor : '';
  const transactionTabs = [
    {
      header: (
        <div className={classes.tabHeader}>
          <span>Invoice</span>
          <span className={classnames(sharedClasses.cellPill, classes.cellPill)} data-pill-value={invoiceVendor}>
            {invoiceVendor}
          </span>
        </div>
      ),
      content: <PrettyJSON object={invoiceJSONObject} />,
    },
    ...paymentApplications.map((pa, idx) => ({
      header: (
        <div className={classes.tabHeader}>
          <span>Payment {idx + 1}</span>
          <span
            className={classnames(sharedClasses.cellPill, classes.cellPill)}
            data-pill-value={pa.paymentEntity.vendor}
          >
            {pa.paymentEntity.vendor}
          </span>
        </div>
      ),
      content: <PrettyJSON object={pa.rawJSON || ''} />,
    })),
  ];

  if (oandaJSONObject) {
    transactionTabs.push({
      header: (
        <div className={classes.tabHeader}>
          <span>Currency </span>
          <span className={classnames(sharedClasses.cellPill, classes.cellPill)} data-pill-value={TVendor.OANDA}>
            {TVendor.OANDA}
          </span>
        </div>
      ),
      content: <PrettyJSON object={oandaJSONObject} />,
    });
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner showSearchBar />
      <div
        className={sharedClasses.contentWrapper}
        style={{
          marginTop: '-250px',
        }}
      >
        {isLoading || !data ? (
          <div className={sharedClasses.contentLoaderContainer}>
            <LoaderAnimation height={80} />
          </div>
        ) : (
          <>
            <div className={sharedClasses.contentHeaderWrapper}>
              <div className={sharedClasses.contentHeader}>
                <div className={sharedClasses.cardTabs}>
                  <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
                    <span>Line Item: {data.invoiceItemDetails.id}</span>
                  </div>
                </div>
              </div>
              <div className={sharedClasses.contentHeaderUtils}></div>
            </div>
            <div>
              <SectionWithHeader
                header="Line Item"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.LineItem)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: data.invoiceItemDetails.id,
                        },
                        {
                          key: 'Description:',
                          value: data.invoiceItemDetails.entity.description,
                        },
                        {
                          key: 'Amount invoiced:',
                          value: data.invoiceItemDetails.entity.formattedAmount,
                        },
                        {
                          key: 'Net amount paid:',
                          value: data.invoiceItemDetails.netPaidAmountFormatted,
                        },
                        {
                          key: 'Product SKU:',
                          value: data.invoiceItemDetails.priceListItem?.entity?.sku || '',
                        },
                        {
                          key: 'Product list price:',
                          value: data.invoiceItemDetails.priceListItem?.entity?.formattedAmount || '',
                        },
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{ height: invoiceItemJSONHeightPx }}
                      tabs={[
                        {
                          header: (
                            <div className={classes.tabHeader}>
                              <span>Invoice Item</span>
                              <span
                                className={classnames(sharedClasses.cellPill, classes.cellPill)}
                                data-pill-value={data.invoiceItemDetails.vendor}
                              >
                                {data.invoiceItemDetails.vendor}
                              </span>
                            </div>
                          ),
                          content: <PrettyJSON object={invoiceItemJSONObject} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </SectionWithHeader>

              <SectionWithHeader
                header="Transaction"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Transaction)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: data.invoiceItemDetails.invoice?.id || '',
                        },
                        {
                          key: 'Amount due:',
                          value: data.invoiceItemDetails.invoice?.entity?.formattedAmount || '',
                        },
                        {
                          key: 'Due at:',
                          value: data.invoiceItemDetails.invoice?.entity?.dueAt
                            ? moment(new Date(data.invoiceItemDetails.invoice.entity.dueAt)).format(
                                DateFormatYearMonthDateTime
                              )
                            : '',
                        },
                        {
                          key: 'Line items:',
                          value: (
                            <ul>
                              {(data.invoiceItemDetails.invoice?.invoiceItems || []).map((ea, idx) => (
                                <li key={`invoice-item-${idx}`}>{ea.entity.description}</li>
                              ))}
                            </ul>
                          ),
                        },
                        {
                          key: 'Payments:',
                          value: paymentApplications.length ? (
                            <ul>
                              {paymentApplications.map((ea, idx) => (
                                <li key={`payment-${idx}`}>
                                  {ea.paymentEntity.formattedAmount} ({ea.entity.formattedAmount} applied) at{' '}
                                  {moment(ea.paymentEntity.completedAt).format(DateFormatYearMonthDateTime)}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            'N/A'
                          ),
                        },
                        {
                          key: 'Refunds:',
                          value: refunds.length ? (
                            <ul>
                              {refunds.map((ea, idx) => (
                                <li key={`refund-${idx}`}>
                                  {ea.entity.formattedAmount} at{' '}
                                  {moment(ea.entity.completedAt).format(DateFormatYearMonthDateTime)}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            'N/A'
                          ),
                        },
                        {
                          key: 'Net amount paid:',
                          value: data.invoiceItemDetails.invoice?.netPaidAmountFormatted || '',
                        },
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{
                        height: transactionJSONHeightPx,
                      }}
                      tabs={transactionTabs}
                    />
                  </div>
                </div>
              </SectionWithHeader>

              <SectionWithHeader
                header="Contract"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Contract)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: contract?.id || '',
                        },
                        {
                          key: 'Description:',
                          value: contract?.entity.description || '',
                        },
                        {
                          key: 'State:',
                          value: contract?.entity.state || '',
                        },
                        {
                          key: 'Invoice count:',
                          value: (contract?.invoices || []).length,
                        },
                        {
                          key: 'Is Subscription:',
                          value: String(!!contract?.entity.isSubscription),
                        },
                        ...(contract?.entity.isSubscription &&
                        contract?.entity.intervalUnit &&
                        contract?.entity.intervalLength
                          ? [
                              {
                                key: 'Subscription length:',
                                value: `${contract?.entity.intervalLength} ${pluralize(
                                  contract?.entity.intervalUnit.toLowerCase(),
                                  contract?.entity.intervalLength
                                )}`,
                              },
                            ]
                          : []),
                        {
                          key: contract?.entity.isSubscription ? 'Starts at:' : 'Contract entered at:',
                          value: contract?.entity.startsAt
                            ? moment(new Date(contract?.entity.startsAt)).format(DateFormatYearMonthDateTime)
                            : '',
                        },
                        ...(contract?.entity.isSubscription && contract?.entity.endsAt
                          ? [
                              {
                                key: 'Ends at:',
                                value: moment(new Date(contract?.entity.endsAt)).format(DateFormatYearMonthDateTime),
                              },
                            ]
                          : []),
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{ height: contractJSONHeightPx }}
                      tabs={[
                        {
                          header: (
                            <div className={classes.tabHeader}>
                              <span>Contract</span>
                              <span
                                className={classnames(sharedClasses.cellPill, classes.cellPill)}
                                data-pill-value={contract?.entity.vendor}
                              >
                                {contract?.entity.vendor}
                              </span>
                            </div>
                          ),
                          content: <PrettyJSON object={contractJSONObject || {}} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </SectionWithHeader>

              <SectionWithHeader
                header="Customer"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Customer)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: customer?.id || '',
                        },
                        {
                          key: 'Contract count:',
                          value: (customer?.contracts || []).length,
                        },
                        {
                          key: 'External ID:',
                          value: customer?.vendorExternalId || '',
                        },
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{ height: customerJSONHeightPx }}
                      tabs={[
                        {
                          header: (
                            <div className={classes.tabHeader}>
                              <span>Customer</span>
                              <span
                                className={classnames(sharedClasses.cellPill, classes.cellPill)}
                                data-pill-value={customer?.vendor}
                              >
                                {customer?.vendor}
                              </span>
                            </div>
                          ),
                          content: <PrettyJSON object={customerJSONObject || {}} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </SectionWithHeader>
              {credit?.id && (
                <SectionWithHeader
                  header="Credit"
                  isCollapsible
                  isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Customer)}
                  bodyPaddingPx={0}
                >
                  <div className={classes.columnContainer}>
                    <div className={classes.column} data-header="Numeral Data">
                      <KeyValueList
                        keyValuePairs={[
                          {
                            key: 'Numeral ID:',
                            value: credit?.id || '',
                          },
                          {
                            key: 'Description:',
                            value: credit?.entity.description || '',
                          },
                          {
                            key: 'External ID:',
                            value: credit?.entity.vendorExternalId || '',
                          },
                        ]}
                      />
                    </div>
                    <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                      <TabbedContainers
                        tabContentClass={classes.rawJSONContainer}
                        tabContentStyle={{ height: customerJSONHeightPx }}
                        tabs={[
                          {
                            header: (
                              <div className={classes.tabHeader}>
                                <span>Credit</span>
                                <span className={classnames(sharedClasses.cellPill, classes.cellPill)}>
                                  {credit.rawJSON}
                                </span>
                              </div>
                            ),
                            content: <PrettyJSON object={customerJSONObject || {}} />,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </SectionWithHeader>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DataHubLineItemCardWithBanner;
