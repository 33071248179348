import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator_simple.min.css';

import React from 'react';

import { Classes } from 'jss';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ReactTabulator } from 'react-tabulator';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';

import { CellPillFragmentFragment } from '../../generated/graphql';
import { DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX } from '../../utils/TableConstants';
import DataGridColumnLockMonthModal from './modals/DataGridColumnLockMonthModal';
import DataGridColumnLockMonthWithGlModal from './modals/DataGridColumnLockMonthWithGlModal';
import LoaderAnimation from '../common/LoaderAnimation';
import PagingControls from '../PagingControls';
import TableColumnFilterDateModal from '../common/TableColumnFilterDateModal';
import TableColumnFilterStrModal from '../common/TableColumnFilterStrModal';
import { cellTooltip } from './utils/cellTooltip.ts';
import { customCssFormatter } from './utils/customCssFormatter.ts';
import { dateFilterHeaderClick } from './utils/dateFilterHeaderClick.ts';
import { stringFilterHeaderClick } from './utils/stringFilterHeaderClick.ts';
import { useLinkFormatter } from './hooks/useLinkFormatter.ts';
import { useSharedStyles } from '../../utils/CssUtil';
import { useStyles } from './styles';
import { DataGridFormatter, DataGridRow } from '../../components/data_grid/DataGridTypes.ts';
import {
  DataHubURLParam,
  DataHubURLParamToGraphQLParam,
  DateFilter,
  Sort,
  SortDirections,
  StringToStringMap,
  SupportedSortKeys,
} from '../../../src/types/DataHubTypes';
import FormatUtil, { DateFormatYearMonthDate, DateFormatYearMonthDateTime } from '../../../src/utils/FormatUtil';

export const dateFormatter = (cell: TabulatorTypes.CellComponent, tz?: Maybe<string>) => {
  const dateVal = tz ? moment(cell.getValue()).tz(tz) : moment(cell.getValue());
  if (dateVal.isValid()) {
    return dateVal.format(DateFormatYearMonthDate);
  }
  return cell.getValue();
};

export const dateTimeFormatter = (cell: TabulatorTypes.CellComponent, tz?: Maybe<string>) => {
  const dateVal = tz ? moment(cell.getValue()).tz(tz) : moment(cell.getValue());
  if (dateVal.isValid()) {
    return dateVal.format(DateFormatYearMonthDateTime);
  }
  return cell.getValue();
};

export const pillFormatter = (cell: TabulatorTypes.CellComponent, sharedClasses: Classes) => {
  const data = cell.getData() as DataGridRow;
  const pills = (data?._cellData?.[cell.getField()]?.pills || []) as CellPillFragmentFragment[];

  const spanEl = document.createElement('div');
  spanEl.classList.add(sharedClasses.pillList);

  pills?.map((pill, idx) => {
    const el = pill.url ? document.createElement('a') : document.createElement('span');
    el.classList.add(sharedClasses.cellPill);
    el.setAttribute('key', `pill-${pill.value}-${idx}`);
    el.setAttribute('data-pill-category', pill.category);
    el.setAttribute('data-pill-value', pill.value);
    el.innerText = pill.label;

    if (pill.url) {
      el.classList.add(sharedClasses.cellPillA);
      el.setAttribute('href', pill.url);
    }
    spanEl.appendChild(el);
  });

  return spanEl;
};

export const lockMonthHeaderClick = (
  column: TabulatorTypes.ColumnComponent,
  toggleVisibility: React.Dispatch<React.SetStateAction<boolean>>,
  setLockMonthParams: React.Dispatch<
    React.SetStateAction<
      | {
          timespanStart?: Date | undefined;
          isLocked?: boolean | undefined;
          isRealCustomerCompany?: boolean | undefined;
        }
      | undefined
    >
  >
) => {
  const colDef = column.getDefinition();
  const cellRecord = colDef.accessorParams as {
    timespanStart: Date;
    isLocked: boolean;
    isRealCustomerCompany: boolean;
    useNewMethod: boolean;
  };

  if (!cellRecord.isLocked) {
    if (cellRecord.timespanStart) {
      setLockMonthParams(cellRecord);
      toggleVisibility(true);
    }
  }
};

const getDropdownPosition = (column?: TabulatorTypes.ColumnComponent) => {
  const td = column?.getElement();
  const tbl = column?.getTable().element;
  if (!td || !tbl) {
    return;
  }
  const viewportWidthPx = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const tdRect = td.getBoundingClientRect();
  const tblRect = tbl.getBoundingClientRect();

  let leftOffsetPx = tdRect.x - tblRect.x;
  if (viewportWidthPx - (leftOffsetPx + tdRect.width) <= DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX) {
    leftOffsetPx = leftOffsetPx + tdRect.width - DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX;
  }

  return {
    left: leftOffsetPx,
    top: tdRect.height - 25,
  };
};

export type DataGridProps = {
  data?: DataGridRow[];
  options: TabulatorTypes.Options;
  columns: TabulatorTypes.ColumnDefinition[];
  events?: Partial<TabulatorTypes.OptionsGeneral | TabulatorTypes.OptionsFiltering> | any;
  className?: string;
  expandedRows?: string[] | undefined;
  onToggleRow?: (id: string) => void;
  shouldEnableDataDownload?: boolean;
  shouldEnablePager?: boolean;
  pagerVariables?: {
    currentPage: number;
    pageCount: number;
    pageSize: number;
    refetchPage: (p: number, ps: number) => void;
    shouldDisableChangingPageSize?: boolean;
  };
  shouldEnableUrlFiltering?: boolean;
  shouldEnableUrlSorting?: boolean;
  setDgRef?: React.Dispatch<
    React.SetStateAction<
      | {
          current: TabulatorTypes;
        }
      | undefined
    >
  >;
};

const DataGrid: React.FunctionComponent<DataGridProps> = ({
  data,
  columns,
  options,
  events,
  className,
  shouldEnableDataDownload = false,
  shouldEnablePager = false,
  pagerVariables,
  shouldEnableUrlFiltering = false,
  shouldEnableUrlSorting = false,
  setDgRef,
}) => {
  const linkFormatter = useLinkFormatter();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const [params, setSearchParams] = useSearchParams();
  const [isDateSelectorVisible, updateIsDateSelectorVisible] = React.useState(false);
  const [dateFilterParams, setDateFilterParams] = React.useState<{
    column?: TabulatorTypes.ColumnComponent;
    start?: string;
    end?: string;
  }>();
  const [isStringColFilterVisible, updateIsStringColFilterVisible] = React.useState(false);
  const [stringFilterParams, setStringFilterParams] = React.useState<{
    column?: TabulatorTypes.ColumnComponent;
    label?: string;
    field?: string;
  }>();

  const [isLockMonthVisible, updateIsLockMonthVisible] = React.useState(false);
  const [lockMonthParams, setLockMonthParams] = React.useState<{
    timespanStart?: Date;
    isLocked?: boolean;
    isRealCustomerCompany?: boolean;
    useNewMethod?: boolean;
  }>();

  // TODO: isLockMonthWithGlVisible will be used once the rest of the logic is settled for the With GL method
  const [isLockMonthWithGlVisible, updateIsLockMonthWithGlVisible] = React.useState(false);

  const [isTableBuilding, setIsTableBuilding] = React.useState(true);

  let ref = React.useRef<any>();

  const [renderDataGrid, setRenderDataGrid] = React.useState(false);
  React.useEffect(() => {
    // this setTimeout ensures that the LoaderAnimation appears first every time
    setTimeout(() => {
      setRenderDataGrid(true);
    }, 10);
  });

  const replaceSearchParams = (obj: StringToStringMap) => {
    const newParams = new URLSearchParams();
    Object.keys(obj).forEach((k) => {
      newParams.set(k, obj[k]);
    });
    setSearchParams(newParams);
  };

  const updateSearchParams = (newParams: StringToStringMap) => {
    // create new params from existing
    const newSearchParams: StringToStringMap = {};
    params.forEach((value, key) => {
      if (!newSearchParams[key]) {
        newSearchParams[key] = value;
      }
    });
    // add new parameters or overwrite
    replaceSearchParams({ ...newSearchParams, ...newParams });
  };

  const dateFilter: DateFilter = {};
  if (shouldEnableUrlFiltering) {
    Object.keys(DataHubURLParamToGraphQLParam).forEach((filterType) => {
      const val = params.get(filterType);
      if (val) {
        const dateField = DataHubURLParamToGraphQLParam[filterType as DataHubURLParam];
        if (dateField) {
          dateFilter[dateField] = Number(val);
        }
      }
    });
  }

  let sort: Sort | undefined = undefined;
  if (
    shouldEnableUrlSorting &&
    params.get(DataHubURLParam.SORT_KEY) &&
    ['asc', 'des'].indexOf(params.get(DataHubURLParam.SORT_DIRECTION) || '') >= 0
  ) {
    sort = {
      key: (params.get(DataHubURLParam.SORT_KEY) || '') as SupportedSortKeys,
      direction:
        params.get(DataHubURLParam.SORT_DIRECTION) === SortDirections.ASC ? SortDirections.ASC : SortDirections.DESC,
    };
  }

  const gridDefaultOptions: TabulatorTypes.Options = {
    layout: 'fitColumns', // 'fitDataTable' 'fitData' 'fitDataFill',
    selectable: false,
    movableColumns: false,
    movableRows: false,
    resizableRows: false,
    sortMode: 'local',
    columnDefaults: {
      title: '',
      cssClass: sharedClasses.contentCellTextWrapped,
      headerSortTristate: true,
      headerTooltip: true,
      tooltip: (cell: TabulatorTypes.CellComponent) => cellTooltip(cell, classes),
      variableHeight: true,
    },
    initialSort:
      shouldEnableUrlSorting && sort?.key && sort?.direction
        ? [
            {
              column: sort.key,
              dir: sort.direction === SortDirections.ASC ? 'asc' : 'desc',
            } as TabulatorTypes.Sorter,
          ]
        : undefined,
    persistence: true,
  };

  // TODO: develop DataGrid events for upcoming features
  const defaultEvents: Partial<
    | TabulatorTypes.OptionsGeneral
    | TabulatorTypes.OptionsFiltering
    | TabulatorTypes.OptionsRowGrouping
    | { dataLoading: (data: DataGridRow[]) => void; dataLoaded: (data: DataGridRow[]) => void }
  > = {
    tableBuilding: () => {
      // console.log('tableBuilding', isTableBuilding, ref);
      setIsTableBuilding(true);
    },
    // TODO: implement logic for dataSorting
    dataSorting: (sorters: TabulatorTypes.Sorter[]) => {
      // console.log('dataSorting', isTableBuilding, ref);
      if (sorters?.length === 0) {
        const newSearchParams: StringToStringMap = {};
        params.forEach((value, key) => {
          if (![DataHubURLParam.SORT_DIRECTION.toString(), DataHubURLParam.SORT_KEY.toString()].includes(key)) {
            newSearchParams[key] = value;
          }
        });
        replaceSearchParams(newSearchParams);
      } else {
        const newParams = sorters?.reduce((acc, cur) => {
          const colComp = cur.column as unknown as TabulatorTypes.ColumnComponent;
          return {
            ...acc,
            [DataHubURLParam.SORT_KEY]: colComp.getField(),
            [DataHubURLParam.SORT_DIRECTION]: cur.dir === SortDirections.ASC ? SortDirections.ASC : SortDirections.DESC,
          };
        }, {} as StringToStringMap);
        updateSearchParams(newParams);
      }
    },

    // TODO: implement logic for tableBuilt
    tableBuilt: () => {
      setIsTableBuilding(false);
    },
  };

  // add click events if defined
  const updatedColumns = columns.map((col) => {
    const customFormatter = (col.formatterParams as TabulatorTypes.JSONRecord)?.customFormatter;
    const timezone = (col.formatterParams as TabulatorTypes.JSONRecord)?.timezone?.toString();
    const clickType = (col.accessorParams as TabulatorTypes.JSONRecord)?.clickType;

    if (clickType === 'lockMonthHeaderClick') {
      const useNewMethod = (col.accessorParams as TabulatorTypes.JSONRecord)?.useNewMethod;

      col.headerClick = (_event: UIEvent, column: TabulatorTypes.ColumnComponent) =>
        lockMonthHeaderClick(
          column,
          useNewMethod ? updateIsLockMonthWithGlVisible : updateIsLockMonthVisible,
          setLockMonthParams
        );
    } else {
      if (shouldEnableUrlFiltering) {
        switch (clickType) {
          case 'dateFilterHeaderClick':
            col.headerClick = (_event: UIEvent, column: TabulatorTypes.ColumnComponent) =>
              dateFilterHeaderClick(column, updateIsDateSelectorVisible, setDateFilterParams);
            break;
          case 'stringFilterHeaderClick':
            col.headerClick = (_event: UIEvent, column: TabulatorTypes.ColumnComponent) =>
              stringFilterHeaderClick(column, updateIsStringColFilterVisible, setStringFilterParams);
            break;
        }
      }
      col.cssClass = classnames(col.headerClick ? sharedClasses.dataGridHeaderFilter : '', col.cssClass);
    }

    if (shouldEnableUrlSorting) {
      col.headerSort =
        Object.values(SupportedSortKeys).includes(col.field as SupportedSortKeys) && col.headerClick === undefined;
    }

    if (customFormatter) {
      switch (customFormatter) {
        case DataGridFormatter.LINK_FORMATTER:
          col.formatter = linkFormatter;
          break;
        case DataGridFormatter.PILL_FORMATTER:
          col.formatter = (cell: TabulatorTypes.CellComponent) => pillFormatter(cell, sharedClasses);
          break;
        case DataGridFormatter.DATE_FORMATTER:
          col.formatter = (cell: TabulatorTypes.CellComponent) => dateFormatter(cell, timezone);
          break;
        case DataGridFormatter.DATETIME_FORMATTER:
          col.formatter = (cell: TabulatorTypes.CellComponent) => dateTimeFormatter(cell, timezone);
          break;
        case DataGridFormatter.CUSTOMCSS_FORMATTER:
          col.formatter = customCssFormatter;
          break;
      }
    }

    return col;
  });
  const tabulatorProps = {};
  if (data) {
    tabulatorProps['data'] = data;
  }

  return (
    <div className={classes.dataGridWrapper}>
      {shouldEnableDataDownload ? (
        <button
          onClick={() => {
            ref?.current?.download?.('csv', 'data.csv', { delimiter: '.' });
          }}
        >
          Download CSV
        </button>
      ) : null}
      {isTableBuilding ? (
        <div className={classnames(sharedClasses.contentLoaderContainer)}>
          <LoaderAnimation height={80} />
        </div>
      ) : null}
      {renderDataGrid && (
        <ReactTabulator
          onRef={(r) => {
            ref = r;
            setDgRef?.(r);
          }}
          className={classnames(sharedClasses.tabulator, classes.tabulatorContainer, className)}
          columns={updatedColumns}
          options={{
            dataTree: true,
            dataTreeStartExpanded: true,
            ...gridDefaultOptions,
            ...options,
            ...{ columnDefaults: { ...gridDefaultOptions.columnDefaults, ...options?.columnDefaults } },
          }}
          events={{ ...defaultEvents, ...events }}
          placeholder="There's no data in the current view. Try applying a different filter or refreshing the page."
          {...tabulatorProps}
        />
      )}
      {isDateSelectorVisible && (
        <TableColumnFilterDateModal
          currentDateFilter={dateFilter}
          dateFilterStartField={dateFilterParams?.start}
          dateFilterEndField={dateFilterParams?.end}
          onColFilter={updateSearchParams}
          getDropdownPosition={() => getDropdownPosition(dateFilterParams?.column)}
          updateIsDateSelectorVisible={updateIsDateSelectorVisible}
        />
      )}
      {isStringColFilterVisible && (
        <TableColumnFilterStrModal
          label={stringFilterParams?.label || ''}
          colKey={stringFilterParams?.field}
          onColFilter={updateSearchParams}
          getDropdownPosition={() => getDropdownPosition(stringFilterParams?.column)}
          updateIsStringColFilterVisible={updateIsStringColFilterVisible}
          searchParams={params}
        />
      )}
      {isLockMonthVisible && (
        <DataGridColumnLockMonthModal
          lockMonthStr={
            lockMonthParams?.timespanStart ? FormatUtil.dateToMonthStr(lockMonthParams.timespanStart) : undefined
          }
          toggleVisibility={updateIsLockMonthVisible}
          useNewMethod={lockMonthParams?.useNewMethod}
        />
      )}
      {isLockMonthWithGlVisible && (
        <DataGridColumnLockMonthWithGlModal
          lockMonthStr={
            lockMonthParams?.timespanStart ? FormatUtil.dateToMonthStr(lockMonthParams.timespanStart) : undefined
          }
          toggleVisibility={updateIsLockMonthWithGlVisible}
        />
      )}
      {shouldEnablePager && pagerVariables && !isTableBuilding && (
        <div className={sharedClasses.afterTable}>
          <div className={sharedClasses.afterTableLeft}>
            <PagingControls
              canPageForward={pagerVariables.currentPage < pagerVariables.pageCount}
              canPageBackward={pagerVariables.currentPage > 1}
              currentPage={pagerVariables.currentPage}
              pageCount={pagerVariables.pageCount}
              pageSize={pagerVariables.pageSize}
              refetchPage={pagerVariables.refetchPage}
              shouldDisableChangingPageSize={pagerVariables?.shouldDisableChangingPageSize}
            />
          </div>
          <div className={sharedClasses.afterTableRight}>Generated at {new Date().toString()}</div>
        </div>
      )}
    </div>
  );
};

export default DataGrid;
