import React from 'react';

import classnames from 'classnames';

import { Credit } from '../../../../generated/graphql.tsx';
import KeyValueList from '../../../../components/common/KeyValueList.tsx';
import PrettyJSON from '../../../../components/common/PrettyJSON.tsx';
import SectionWithHeader from '../../../../components/common/SectionWithHeader.tsx';
import TabbedContainers from '../../../../components/common/TabbedContainers.tsx';
import { getJSONHeight } from '../../utils/getJSONHeight.ts';
import { useSharedStyles } from '../../../../utils/CssUtil.ts';
import { useStyles } from '../../styles.ts';

type CreditDetailsProps = {
  credit: Credit;
};

export const CreditDetails = ({ credit }: CreditDetailsProps) => {
  const sharedClasses = useSharedStyles();

  const classes = useStyles();

  const customerJSONHeightPx = getJSONHeight(credit?.rawJSON ? Object.keys(credit.rawJSON).length : 0);

  return (
    <>
      <div className={sharedClasses.contentHeaderWrapper}>
        <div className={sharedClasses.contentHeader}>
          <div className={sharedClasses.cardTabs}>
            <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
              <span>Line Item: {credit?.id}</span>
            </div>
          </div>
        </div>
        <div className={sharedClasses.contentHeaderUtils}></div>
      </div>
      <SectionWithHeader header="Credit" isCollapsible isCollapsedInitially={false} bodyPaddingPx={0}>
        <div className={classes.columnContainer}>
          <div className={classes.column} data-header="Numeral Data">
            <KeyValueList
              keyValuePairs={[
                {
                  key: 'Numeral Id',
                  value: credit?.id || 'N/A',
                },
                {
                  key: 'Description',
                  value: credit?.entity.description || 'N/A',
                },
                {
                  key: 'Vendor',
                  value: credit?.entity.vendor || 'N/A',
                },
                {
                  key: 'Discount Amount',
                  value: credit?.formattedBaseCurrencyAmount ?? 0,
                },
                {
                  key: 'Source',
                  value: credit?.source ?? 'N/A',
                },
                {
                  key: 'Order',
                  value: credit?.orderType ?? 'N/A',
                },
              ]}
            />
          </div>
          <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
            <TabbedContainers
              tabContentClass={classes.rawJSONContainer}
              tabContentStyle={{ height: customerJSONHeightPx }}
              tabs={[
                {
                  header: (
                    <div className={classes.tabHeader}>
                      <span>Credit</span>
                      <span className={classnames(sharedClasses.cellPill, classes.cellPill)}>
                        {credit?.entity.vendor}
                      </span>
                    </div>
                  ),
                  content: <PrettyJSON object={credit?.rawJSON || {}} />,
                },
              ]}
            />
          </div>
        </div>
      </SectionWithHeader>
    </>
  );
};
