import React, { useEffect, useRef, useState } from 'react';

import { createUseStyles } from 'react-jss';
import pluralize from 'pluralize';
import { useSearchParams } from 'react-router-dom';

import { gql } from '@apollo/client';

import Colors from './common/Colors';
import getAssetPath from '../utils/AssetPathUtil';
import { parseCompanyName } from '../features/app/helpers/parseCompanyName.ts';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import {
  useSearchInvoiceItemsLazyQuery,
  useSearchRecognitionScheduleLazyQuery,
  useSearchRevenueTransactionDrilldownV2LazyQuery,
} from '../generated/graphql';

const useStyles = createUseStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'stretch',
    paddingLeft: '28px',
    marginLeft: '-12px',
    position: 'relative',

    '& img': {
      height: '16px',
    },
  },

  searchBar: {
    background: 'transparent',
    border: `1px solid ${Colors.WHITE}`,
    borderRadius: '5px',
    color: Colors.WHITE,
    flex: 1,
    fontSize: '16px',
    marginLeft: '-28px',
    marginRight: 'max(20px, 5vw)',
    minWidth: '200px',
    padding: '10px 15px 10px 37px',
    transition: 'padding 0.1s, background-color 0.1s, transform 0.1s',

    '&::placeholder': {
      color: Colors.WHITE,
    },

    '&:focus': {
      background: Colors.WHITE,
      boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
      color: Colors.DARK_GRAY,
      outlineWidth: 0,
      outline: 'none',
      paddingLeft: '15px',
    },

    '&:focus::placeholder': {
      color: Colors.DARK_GRAY,
    },
  },

  dropdown: {
    background: Colors.WHITE,
    color: Colors.DARK_GRAY,

    boxSizing: 'border-box',
    boxShadow: '1px 1px 2px rgba(0,0,0,0.05)',
    borderRadius: '4px',
    fontSize: '14px',
    padding: '10px 0',

    position: 'absolute',
    left: '12px',
    top: '48px',
    width: 'calc(100% - 160px)',
    zIndex: 1000,
  },
  link: {
    color: Colors.DARK_GRAY,
    display: 'block',
    padding: '10px 20px',

    '&:hover': {
      color: Colors.WHITE,
      background: Colors.MAIN_BLUE,
    },
  },
  linkHeader: {
    fontWeight: 'bold',
    marginBottom: '5px',
  },
});

gql`
  query SearchInvoiceItems($query: String!) {
    searchInvoiceItems(query: $query) {
      header
      resultCount
      resultLabel
      url
    }
  }

  query SearchRevenueTransactionDrilldownV2($query: String!) {
    searchRevenueTransactionDrilldownV2(query: $query) {
      header
      resultCount
      resultLabel
      url
    }
  }

  query SearchRecognitionSchedule($query: String!) {
    searchRecognitionSchedule(query: $query) {
      header
      resultCount
      resultLabel
      url
    }
  }
`;

export enum TSearchBarLazyQueryFn {
  SEARCH_INVOICE_ITEMS = 'searchInvoiceItems',
  SEARCH_REVENUE_TRANSACTION_DRILLDOWN_V2 = 'searchRevenueTransactionDrilldownV2',
  SEARCH_RECOGNITION_SCHEDULE = 'searchRecognitionSchedule',
}

type TSearchBarProps = {
  queryLazyFn?: TSearchBarLazyQueryFn;
};

const SearchBar: React.FunctionComponent<TSearchBarProps> = ({ queryLazyFn }: TSearchBarProps) => {
  const { currentCompany } = useCompanyContext();
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [_searchParams, setSearchParams] = useSearchParams();

  let queryFn:
    | typeof useSearchInvoiceItemsLazyQuery
    | typeof useSearchRevenueTransactionDrilldownV2LazyQuery
    | typeof useSearchRecognitionScheduleLazyQuery;
  switch (queryLazyFn) {
    case TSearchBarLazyQueryFn.SEARCH_REVENUE_TRANSACTION_DRILLDOWN_V2:
      queryFn = useSearchRevenueTransactionDrilldownV2LazyQuery;
      break;
    case TSearchBarLazyQueryFn.SEARCH_RECOGNITION_SCHEDULE:
      queryFn = useSearchRecognitionScheduleLazyQuery;
      break;
    default:
      queryFn = useSearchInvoiceItemsLazyQuery;
      break;
  }

  const classes = useStyles();
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [getData, { loading: isLoading, data }] = queryFn() as ReturnType<typeof useSearchInvoiceItemsLazyQuery> &
    ReturnType<typeof useSearchRevenueTransactionDrilldownV2LazyQuery> &
    ReturnType<typeof useSearchRecognitionScheduleLazyQuery>;
  const response =
    data?.searchInvoiceItems || data?.searchRevenueTransactionDrilldownV2 || data?.searchRecognitionSchedule;

  const setQueryParamsFn = (url?: string) => {
    if (!url) {
      return;
    }
    const urlToSplit = url.replace(/\?/g, '');
    const params = urlToSplit.split(/&/g).reduce((acc, param) => {
      const [key, value] = param.split('=');
      if (!param === undefined || !key === undefined || !value === undefined) {
        return acc;
      }
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {} as { [key: string]: string });
    setSearchParams(params);
  };

  const formatLink = (link: string) => `/app/${parseCompanyName(currentCompany.name)}${link}`;

  return (
    <div className={classes.container}>
      <img src={`${getAssetPath()}/images/icon_search.svg`} />
      <input
        className={classes.searchBar}
        name="search"
        type="text"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          if (e.target.value) {
            getData({ variables: { query: e.target.value } });
          }
        }}
        placeholder="Search for invoice line items by source ID"
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          window.setTimeout(() => {
            if (isMounted.current) {
              setIsFocused(false);
            }
          }, 500);
        }}
        autoComplete="off"
      />
      {isFocused && searchTerm ? (
        <div className={classes.dropdown}>
          {isLoading ? (
            <div className={classes.link}>
              <div className={classes.linkHeader}>Searching: {searchTerm}</div>
              <div> </div>
            </div>
          ) : data && response && response.length ? (
            response?.map((r, idx) => (
              <a
                key={'res' + idx}
                href={r.resultCount && r.url.startsWith('?') ? undefined : formatLink(r.url)}
                onClick={r.resultCount && r.url.startsWith('?') ? () => setQueryParamsFn(formatLink(r.url)) : undefined}
                className={classes.link}
              >
                <div className={classes.linkHeader}>{r?.header}</div>
                <div>
                  {r?.resultCount} {pluralize(r?.resultLabel || 'Line Item', r.resultCount)} found
                </div>
              </a>
            ))
          ) : (
            <div className={classes.link}>
              <div className={classes.linkHeader}>Searching: {searchTerm}</div>
              <div>No result found</div>
            </div>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

export default SearchBar;
