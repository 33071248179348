import React, { useEffect } from 'react';

import { DateTime } from 'luxon';

import Banner from './Banner';
import ErrorMessage from './common/ErrorMessage';
import RevenueCard from './RevenueCard';
import { TSearchBarLazyQueryFn } from './SearchBar';
import { getHomePageRoute } from '../utils/GetHomePageRoute.ts';
import { useCompanyContext } from '../context/CompanyContext.tsx';
import { useRevenueTableContext } from '../context/RevenueTableContext';
import { useSharedStyles } from '../utils/CssUtil';
import { useSideBarContext } from '../features/sideBar/providers/SideBarProvider';

export const getDefaultDashboardYear = (isRealCompany: boolean | undefined, companyName?: string) => {
  const now = DateTime.now();
  const currentMonth = now.month;
  const currentYear = now.year;

  if (isRealCompany === undefined) {
    return undefined;
  }
  if (companyName === 'PayZen 2023') {
    return 2023;
  }
  return isRealCompany ? (currentMonth > 1 ? currentYear : currentYear - 1) : 2022;
};

const RevenueCardWithBanner: React.FunctionComponent = () => {
  const { sideBarWidth, onResetSideBar } = useSideBarContext();
  const sharedClasses = useSharedStyles();
  const { currentCompany, isRealCustomerCompany, hasYearToggle } = useCompanyContext();

  const { loading, error, revenueTable, selectedYear, setSelectedYear } = useRevenueTableContext();

  useEffect(() => {
    if (!currentCompany.id) {
      return;
    }
    // After setting currentCompany, determine and set the default year.
    const defaultYear = getDefaultDashboardYear(isRealCustomerCompany, currentCompany?.name);
    if (!selectedYear && defaultYear !== undefined) {
      setSelectedYear(defaultYear.toString());
    }
  }, [currentCompany, isRealCustomerCompany]);

  useEffect(() => {
    return () => {
      if (window.location.pathname !== getHomePageRoute(currentCompany.name).url) {
        onResetSideBar();
      }
    };
  }, [currentCompany.name, onResetSideBar]);

  const enableLockFeature = !isRealCustomerCompany || !!currentCompany?.settings?.isMonthLockEnabled;

  const useGlMethod = !!enableLockFeature && !!currentCompany?.settings?.isPushToGlEnabled;
  const overviewVersion = currentCompany?.settings?.overviewVersion;

  let highlights: Array<{
    label?: string;
    type?: string;
    value?: string;
  }> = [
    { label: '', type: '', value: '' },
    { label: '', type: '', value: '' },
    { label: '', type: '', value: '' },
  ];
  if (revenueTable) {
    highlights = revenueTable.highlights || [];
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main} style={{ width: `calc(100vw - ${sideBarWidth}px)` }}>
      <Banner
        showSearchBar={!!(overviewVersion && overviewVersion >= 2)}
        searchBarQueryFn={TSearchBarLazyQueryFn.SEARCH_RECOGNITION_SCHEDULE}
        highlights={highlights.map((highlight) => ({
          header: highlight.label || '',
          isLoading: loading,
          value: highlight.value || '',
        }))}
      />
      <RevenueCard
        isLoading={loading}
        isUndergoingMaintenance={currentCompany?.settings?.isUndergoingMaintenance || false}
        data={revenueTable ? revenueTable : undefined}
        hasTimeToggle={hasYearToggle}
        enableLockFeature={enableLockFeature}
        enableLockIcon={enableLockFeature}
        useGlMethod={useGlMethod}
      />
    </div>
  );
};

export default RevenueCardWithBanner;
