import React, { ReactNode, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import LoaderAnimation from '../components/common/LoaderAnimation.tsx';
import { TNavItem } from '../../src/types/BaseTypes.ts';
import { parseCompanyName } from '../features/app/helpers/parseCompanyName.ts';
import { useAppContext } from './AppContext.tsx';
import { useStyles } from './style.ts';
import { CompanyMetadataQuery, CompanyModel, useCompanyMetadataQuery } from '../generated/graphql';

const REVENUE_TABLE_V2_COMPANIES = [
  'Healthie',
  'Close',
  'Scribd',
  'CaptionsAI',
  'BattlBox',
  'Birchbox',
  'Beachly',
  'Retention Brands',
  'Alltrue',
];

type CompanyContextData = {
  currentCompany: CompanyModel;
  isExportAllHistory: boolean;
  companyData: CompanyMetadataQuery | undefined;
  isGabb: boolean;
  isScribd: boolean;
  isHealthie: boolean;
  isNewDeposits: boolean;
  hasYearToggle: boolean;
  isSupportRevenueTableV2: boolean;
  isSupportTransformedRevenue: boolean;
  isRealCustomerCompany: boolean;
  hasZeroAmountRowFilter: boolean;
  isSupportNestedCashTxnPspRow: boolean;
  onSetCurrentCompany: (company: CompanyModel) => void;
};

interface CompanyProviderProps {
  children: ReactNode;
}

const CompanyContext = React.createContext<CompanyContextData>({} as CompanyContextData);

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const styles = useStyles();
  const location = useLocation();
  const { isAuthenticated } = useAppContext();
  const [currentCompany, setCurrentCompany] = useState<CompanyModel>({} as CompanyModel);

  const onSetCurrentCompany = (company: CompanyModel) => {
    setCurrentCompany(company);
    const section = location.pathname.split('/');
    section[2] = parseCompanyName(company?.name);
    navigate(`${section.join('/')}${location.search}`, { state: { companyName: section[2] } });
  };

  const { data } = useCompanyMetadataQuery({
    fetchPolicy: 'cache-and-network',
    variables: { reportName: TNavItem.REVENUE },
    skip: !isAuthenticated,
    onCompleted(data) {
      let company =
        parseCompanyName(data.holdingCompany.name) === params.companyName
          ? (data.holdingCompany as unknown as CompanyModel)
          : null;
      if (!company) {
        company = data.holdingCompany.companies.find(
          ({ name }) => parseCompanyName(name) === params.companyName
        ) as CompanyModel;
      }
      onSetCurrentCompany(company);
    },
  });

  if (!currentCompany.id) {
    return (
      <div className={styles.loaderContainer}>
        <LoaderAnimation height={64} />
      </div>
    );
  }

  const companyName = currentCompany?.name ?? '';

  return (
    <CompanyContext.Provider
      value={{
        companyData: data,
        currentCompany,
        onSetCurrentCompany,
        isGabb: companyName === 'Gabb',
        isScribd: companyName === 'Scribd',
        isHealthie: companyName === 'Healthie',
        isExportAllHistory: ['CaptionsAI'].includes(companyName),
        hasYearToggle: !['Stark Industries', 'PayZen 2023'].includes(companyName),
        isSupportRevenueTableV2: REVENUE_TABLE_V2_COMPANIES.includes(companyName),
        isSupportTransformedRevenue: ['Healthie', 'Scribd'].includes(companyName),
        isRealCustomerCompany: !!currentCompany?.isRealCustomerCompany,
        isNewDeposits: companyName === 'Scribd',
        hasZeroAmountRowFilter: companyName === 'PayZen',
        isSupportNestedCashTxnPspRow: ['BattlBox', 'Scribd'].includes(companyName),
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = (): CompanyContextData => {
  const context = React.useContext(CompanyContext);

  if (!context) {
    throw new Error('useAppContext must be used within an CompanyProvider');
  }

  return context;
};

export default CompanyContext;
